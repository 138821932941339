
.emit-pdf-link {
    color: white;
    text-decoration: none;
}

.swt-emit-nav-element a {
    color: black;
    width: auto;
    height: 35px;
    padding: 8px 20px;
    font-weight: 500;
    background: #f5f5f5;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #2f2f30;
    text-decoration: none;
    display:flex;
    align-items:center;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: hidden;
}
  
.swt-emit-nav-element .active {
    font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
    color: #2f2f30;
    font-weight: 450;
    background: #fff;
    border-radius: 5px 5px 0px 0px;
    border-bottom: none;
}

.swt-emit-loading {
    width: 200px;
    height: 200px;
    margin: 10% auto 0;
}

.swt-emit-landing-boxes-wrapper {
    padding-top: 25px;
    display: grid;
    grid-template-columns: repeat(3, 200px);
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 50px;
}

.emit-table-sort-icons {
    padding-left: 4px;
}
.emit-table-sort-up-arrow {
    padding-bottom: 5px;
}

.swt-emit-datepicker {
    width: 105px;
    height: 40px;
    background-color: #fff;
    padding: 0 0 0 3px;
    margin: 0 11px 0 0;
    border: 2px solid #f5f5f5;
    color: #4a4a4a;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .15px;
    text-indent: 7px;
    border-radius: 5px;
}
.react-datepicker__input-container{
    border-color: "red";
}
  
.swt-emit-datepicker:hover {
    box-shadow: rgb(245 245 245) 2px 2px 4px;
    cursor: pointer;
}