@import url("https://fonts.googleapis.com/css?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

/* @font-face {
  font-family: HelveticaNeue;
  src: local(OpenSans-Regular),
    url(https://fonts.sawatchlabs.com/helvetica-neue/Helvetica45Light22437.woff2)
      format("woff2"),
    url(https://fonts.sawatchlabs.com/helvetica-neue/Helvetica45Light22437.woff)
      format("woff"),
    url(https://fonts.sawatchlabs.com/helvetica-neue/Helvetica45Light_22437.ttf)
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: HelveticaNeue;
  src: local(OpenSans-Bold),
    url(https://fonts.sawatchlabs.com/helvetica-neue/HelveticaNeueBold.woff2)
      format("woff2"),
    url(https://fonts.sawatchlabs.com/helvetica-neue/HelveticaNeueBold2.woff)
      format("woff"),
    url(https://fonts.sawatchlabs.com/helvetica-neue/HelveticaNeueBold.ttf)
      format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
} */

@font-face{
 font-family:  "HelveticaNeue", "Helvetica Neue", "Arimo", "Open Sans", Helvetica, Arial, sans-serif;
 font-style: normal;
 font-weight: normal;
 src: 'https://fonts.googleapis.com/css?family=Arimo&display=swap';
 src: "https://fonts.googleapis.com/css?family=Open+Sans&display=swap";
}

/* Color palette */
:root {
  --light-navy: #12365b;
  --ionev-blue: #5797b6;
  --ionev-green: #35a67d;
  --ionev-red: #e75858;
  --bluish: #259cc4;
  --ionev-light-grey: #f5f5f5;
  --white-two: #ffffff;
  --battleship-grey: #74787d;
  --medium-grey: #828b94;
  --ice-blue: #eef1f2;
}

* {
  margin: 0;
}


/* NEW LANDING PAGE CONTENT */
.swt-ionev-landing-wrapper {
  display: inline-block;
}

.swt-ionev-landing-wrapper .swt-ionev-landing-overall {
  color: var(--battleship-grey);
  font-size: 24px;
  margin-bottom: 32px;
}

.swt-ionev-landing-box-wrapper {
  width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
}

.swt-ionev-landing-box-wrapper .swt-ionev-landing-box,
.swt-ionev-stats-box-wrapper .swt-ionev-stats-box {
  width: 220px;
  height: 125px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.18);
  border: solid 1px rgba(0, 0, 0, 0.08);
  background-color: var(--white-two);
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  /* color: #9b9b9b; */
  color: var(--medium-grey);
}

.swt-ionev-stats-box-wrapper {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 0 auto;
}

.swt-ionev-stats-wrapper-content.single .swt-ionev-stats-box-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  width: 500px;
}

.swt-ionev-stats-box-wrapper .swt-ionev-stats-box {
  height: auto;
}

.swt-ionev-stats-wrapper-content.single .swt-ionev-stats-box-wrapper .swt-ionev-stats-box {
  height: 120px;
}

.swt-ionev-landing-box-wrapper .swt-ionev-landing-box:hover {
/* .swt-ionev-stats-box-wrapper .swt-ionev-stats-box:hover */
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.48);
}

.swt-ionev-landing-box-wrapper .swt-ionev-landing-box .swt-ionev-landing-box-label,
.swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-label {
  font-size: 12px;
  margin-top: 10px;
  padding-left: 6px;
  padding-right: 6px;
}

.swt-ionev-landing-box-wrapper .swt-ionev-landing-box .swt-ionev-landing-box-value,
.swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value{
  font-size: 32px;
  margin-top: 28px;
  color: var(--battleship-grey);
}

.swt-ionev-landing-box-wrapper .swt-ionev-landing-box .swt-ionev-landing-box-value-unit,
.swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value-unit{
  font-size: 24px;
}

/* smaller font and less margin where 3x stats boxes */
.swt-ionev-stats-wrapper-content.triple .swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value {
  font-size: 30px;
  margin-top: 14px;
}

.swt-ionev-stats-wrapper-content.triple .swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value-unit {
  font-size: 23px;
}

.swt-ionev-stats-wrapper-content .tco-label-total-fleet-period {
  font-weight: 600;
  font-size: 16px;
  color: var(--battleship-grey);
  padding-bottom: 8px;
}

.swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value {
  margin-top: 16px;
}

.swt-ionev-stats-wrapper-content.single .swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-value {
  margin-top: 28px;
}

.swt-ionev-stats-box-wrapper .swt-ionev-stats-box .swt-ionev-stats-box-label {
  margin-bottom: 16px;
}
/* END NEW LANDING PAGE CONTENT */

/* NEW table content */
.swt-ionev-table-header-section {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
}

.swt-ionev-table-header-section .ionev-table-download-link-wrapper {
  text-align: right;
}

.swt-ionev-table-wrapper .ionev-h2.table-subtitle {
  font-size: 20px;
}

/* END NEW TABLE CONTENT */

.swt-ionev-app {
  font-family: "HelveticaNeue", "Helvetica Neue", "Arimo", "Open Sans",
    Helvetica, Arial, sans-serif;
  margin-bottom: 100px;
  margin-left: 64px;
  text-align: center;
}

.swt-ionev-page-body {
  padding-top: 60px;
  /* max-width: 2000px; */
}

.ionev-logo {
  width: 70px;
  margin-top: 20px;
  margin-left: 20px;
}

/* Header */
.swt-ionev-header {
  height: 179px;
  background-color: var(--light-navy);
  color: white;
  width: 100%;
  text-align: left;
  z-index: 1000;
}

.swt-ionev-header .ionev-header-text {
  margin-left: 40px;
  margin-top: 23px;
  display: inline-block;
  vertical-align: top;
}

.swt-ionev-header .ionev-header-text-link .ionev-header-text {
  text-decoration: none;
  color: white;
}

.ionev-header-navigation {
  display: grid;
  grid-template-columns: 1fr auto;
}

.ionev-navigation-links {
  display: inline-block;
  margin-top: 40px;
  margin-left: 110px;
}

.ionev-navigation-links .ionev-navigation-div {
  display: inline-block;
  cursor: pointer;
}

.ionev-navigation-links .ionev-navigation-div span {
  display: inline-block;
}

.ionev-navigation-links .ionev-navigation {
  margin-right: 38px;
  display: inline-block;
}

.ionev-navigation-links .ionev-navigation-div .ionev-navigation {
  margin-right: 5px;
}

.ionev-navigation-links .ionev-navigation-div .swt-ionev-navigation-arrow {
  margin-right: 38px;
}

.ionev-header-date-selector-wrapper {
  display: inline-block;
  margin-right: 31px;
  margin-left: 80px;
}

.ionev-header-date-selector {
  width: 94px;
  height: 34px;
  background-color: var(--white-two);
  padding: 0;
  margin: 0 11px 0 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: var(--battleship-grey);
  text-indent: 7px;
}

.ionev-header-date-picker-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-right: 20px;
}

.ionev-header-group-selector-wrapper {
  display: inline-block;
}

/* Loading */
.swt-ionev-loading {
  width: 15vw;
  height: 15vh;
  color: var(--ionev-light-grey);
  margin-left: 40%;
  margin-top: 5rem;
}

/* NEW PAGE CONTROLLER / GRAPH / TABLE STYLING */
/* in page controller - will always wrap graph */
.swt-ionev-page-content {
  /* display: inline; */
  display: grid;
  grid-template-rows: auto auto;
}

.swt-ionev-stats-wrapper {
  text-align: center;
}

.swt-ionev-stats-wrapper-align {
  display: inline-block;
}

.swt-ionev-stats-wrapper-content {
  width: 800px;
  height: 350px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  text-align: center;
}

.swt-ionev-stats-wrapper-content.single {
  width: 800px;
  height: 150px;
  display: inline-block;
  text-align: center;
}

/* where no graph and 3x stats wrappper */
.swt-ionev-stats-wrapper-content.triple-only .swt-ionev-stats-box-wrapper {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.swt-ionev-stats-wrapper-content.triple-only {
  width: 800px;
  height: 150px;
  display: inline-block;
  text-align: center;
}
/* end where no graph and 3x stats wrappper */

.swt-ionev-stats-wrapper .swt-ionev-stats-wrapper-lhs {
  text-align: center;
}

.swt-ionev-graph-wrapper {
  background-color: #e8e9ea;
  position: relative;
  width: 400px;
  height: 280px;
  padding-bottom: 25px;
}

/* .swt-ionev-graph-wrapper .swt-ionev-graph-content {
  height: 300px;
} */

.ionev-graph-title {
  font-size: 13;
  font-weight: bold;
  color: #4a4a4a;
  padding-top: 10px;
}
/* END NEW PAGE CONTROLLER / GRAPH / TABLE STYLING */

/* Table */
.swt-ionev-table-wrapper .swt-ionev-th-instructions {
  color: var(--battleship-grey);
  text-align: left;
  /* margin-bottom: 20px; */
}

.swt-ionev-table-wrapper .swt-ionev-th-explanation-wrapper {
  color: var(--battleship-grey);
  /* opacity: 0; */
  font-size: 12px;
  line-height: 1.38;
  letter-spacing: 0.11px;
  text-align: left;
  position: absolute;
  z-index: 10;
  border-radius: 5px;
  background-color: white;
  color: var(--battleship-grey);
  border: 1px solid grey;
  max-width: 15rem;
  padding: 10px;
  overflow-wrap: break-word;
  height: fit-content;
}

.swt-ionev-table-wrapper .swt-ionev-th-explanation#total-act- {
  border: none;
  background-color: none;
  width: 0.2px;
  height: 0.2px;
}

.swt-ionev-th-explanation-text {
  padding: 0.25rem 0rem 0rem 0.25rem;
  width:inherit; /* width: 100px; */
  white-space: pre-wrap;
  font-weight: normal;
}

.swt-ionev-table-header .ionev-th {
  position: sticky;
  top: 0rem;
}

/* Table download button */
.ionev-table-download-link {
  width: 146px;
  height: 44px;
  border: solid 2px #4a4a4a;
  background-color: var(--white-two);
  display: inline-block;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.18);
  text-decoration: none;
  cursor: pointer;
  height: 43px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  color: #4a4a4a;
}

.ionev-table-download-link:hover {
  box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.18);
}

.ionev-table-download-link p {
  padding-top: 11px;
}

.ionev-header-selectors
  .ionev-header-group-selector-wrapper
  .ionev-header-group-selector,
.swt-ionev-map-container .ionev-map-vcl-selector {
  width: 235px;
  height: 34px;
  border-radius: 6px;
  background-color: var(--white-two);
  cursor: pointer;
}
.ionev-header-selectors
  .ionev-header-date-selector-wrapper
  .ionev-header-selector-label,
.ionev-header-selectors
  .ionev-header-group-selector-wrapper
  .ionev-header-selector-label {
  color: white;
}




/* Fonts */
.ionev-h1 {
  height: 55px;
  font-size: 46px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.76px;
}

.ionev-header-beta {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--ionev-blue);
  font-weight: 600;
  display: inline-block;
  letter-spacing: 0.5px;
  cursor: default;
}

.ionev-h2 {
  /* height: 55px; */
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  text-align: left;
  width: auto;
}

.ionev-navigation {
  height: 21px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white-two);
}

.ionev-navigation.selected {
  padding-bottom: 3px;
  border-bottom: 1px solid var(--white-two);
}

.ionev-navigation:hover {
  color: var(--bluish);
}

.ionev-navigation.selected:hover {
  border-bottom: 1px solid var(--bluish);
}

.swt-ionev-navigation-arrow {
  border: solid var(--ice-blue);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 3px;
  margin-left: 2px;
}

.ionev-header-date-selector-wrapper .ionev-header-selector-label,
.ionev-header-group-selector-wrapper .ionev-header-selector-label,
.swt-ionev-map-container .ionev-map-selector-label,
.swt-ionev-table-wrapper .swt-ionev-th-instructions {
  height: 22px;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.11px;
  color: var(--battleship-grey);
  margin-bottom: 5px;
}

.swt-ionev-vcl-details {
  text-align: left;
  height: 22px;
  opacity: 0.9;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.11px;
  color: var(--battleship-grey);
  /* color: #4a4a4a; */
  padding-bottom: 10px;
}

.swt-ionev-map-container .ionev-map-selector-label {
  color: #4a4a4a;
}

.ionev-popup-item {
  font-weight: normal;
}

.ionev-map-note {
  margin-top: 12px;
  color: var(--medium-grey);
  font-size: 12px;
}

.swt-ionev-button-nearby-evse{
  background-color: white;
  border: 1px solid grey;
  color: var(--battleship-grey);
  border-radius: 5px;
  font-size: 11px;
}

/* Navigation dropdown */
.swt-ionev-header .swt-ionev-dropdown-wrapper {
  position: relative;
  width: 280px;
  background-color: var(--white-two);
  margin-left: 230px;
  margin-top: 2px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.18);
  z-index: 1000;
}

.swt-ionev-header .swt-ionev-dropdown-wrapper .swt-ionev-dropdown {
  display: block;
  text-decoration: none;
  padding: 8px 0 8px 15px;
}

.swt-ionev-header .swt-ionev-dropdown-wrapper .swt-ionev-dropdown:hover {
  background-color: var(--ionev-light-grey);
}

/* Fonts? */
.ionev-selector-1 {
  /* opacity: 0.5; -- font should be opaque */
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: var(--battleship-grey);
}

.swt-ionev-table-header-1 {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: var(--ice-blue);
  padding: 4px 24px;
}

.swt-ionev-table-body-1 {
  height: 18px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: #4a4a4a;
}

/* extra space for fleet displayName */
#fleet-name {
  padding-left: 8px;
  padding-right: 8px;
}

.swt-ionev-table-body-1.no-data-msg {
  margin-bottom: 30px;
}

.swt-ionev-table-body-2 {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #4a4a4a;
}

/* CTA cards landing page */
/* .swt-ionev-navigation-cards-wrapper {
  display: inline-block;
}

.swt-ionev-cta-wrapper {
  text-align: center;
  display: grid;
  grid-template-columns: auto auto auto;
  width: 1000px;
}

.swt-ionev-cta-card {
  width: 300px;
  height: 251px;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.18);
  background-color: var(--white-two);
  display: inline-block;
}

.swt-ionev-cta-card .swt-ionev-cta-header {
  width: 300px;
  height: 63px;
}

.swt-ionev-cta-card.charge-inst .swt-ionev-cta-header {
  background-color: var(--ionev-green);
}

.swt-ionev-cta-card.missed-op .swt-ionev-cta-header {
  background-color: var(--ionev-red);
}

.swt-ionev-cta-card.total-activity .swt-ionev-cta-header {
  background-color: var(--ionev-blue);
}

.swt-ionev-cta-card .swt-ionev-cta-body {
  text-align: center;
  margin-top: 18px;
}

.swt-ionev-cta-card .swt-ionev-cta-body .swt-ionev-cta-link {
  height: 20px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.07px;
  text-align: center;
  color: var(--bluish);
}

.swt-ionev-cta-card .swt-ionev-cta-header .swt-ionev-cta-1 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: -0.1px;
  text-align: center;
  color: var(--white-two);
  text-transform: uppercase;
  padding-top: 7px;
}

.swt-ionev-cta-card .swt-ionev-cta-body .swt-ionev-cta-button {
  width: 146px;
  height: 44px;
  border: solid 2px #979797;
  background-color: var(--white-two);
  display: inline-block;
}

.swt-ionev-cta-card .swt-ionev-cta-body .swt-ionev-cta-button a {
  text-decoration: none;
  display: inline-block;
}

.swt-ionev-cta-card .swt-ionev-cta-body .swt-ionev-cta-button:hover {
  border: solid 2px #4a4a4a;
}

.swt-ionev-cta-card
  .swt-ionev-cta-body
  .swt-ionev-cta-button:hover
  .swt-ionev-button-1 {
  color: #4a4a4a;
}

.swt-ionev-cta-card .swt-ionev-cta-body .swt-ionev-cta-button.first {
  margin: 20px 0;
}

.swt-ionev-button-1 {
  height: 43px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  color: #9b9b9b;
  margin-top: 12px;
} */

/* Tables CSS */
.swt-ionev-table {
  max-height: 500px;
  max-width: 1800px;
  display: block;
  table-layout: fixed;
  overflow-x: scroll;
  overflow-y: auto;
  white-space: nowrap;
  font-size: 14px;
  color: #333;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 10px;
  margin-top: 20px;
}

.swt-ionev-reimbursement-addr {
  /* if styling to stay like this, can add to existing font group, with swt-ionev-th-instructions etc */
  height: 22px;
  opacity: 0.9;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.11px;
  color: var(--battleship-grey);
}

.swt-ionev-table th {
  font-weight: 700;
  color: var(--white-two);
  min-width: 70px;
  text-align: left;
}

.swt-ionev-table td {
  color: #000;
  padding: 0 30px;
  height: 50px;
}

.swt-ionev-table td a {
  color: #000;
  padding: 0;
  height: 50px;
  text-decoration: none;
}

.swt-ionev-table td div {
  text-align: left;
}

.swt-ionev-table td .ionev-table-cell {
  text-align: left;
}

/* .swt-ionev-table td .ionev-table-cell-num,
.swt-ionev-table td .ionev-table-cell-num {
  text-align: right;
}

.swt-ionev-table td .ionev-table-cell-string {
  text-align: left;
} */

.swt-ionev-table .swt-ionev-table-header tr th {
  cursor: default;
  padding: 15px 5px 8px 5px;
  font-size: 16px;
}

.swt-ionev-table.charging-events .swt-ionev-table-header tr th {
  background-color: var(--ionev-green);
  padding-left: 30px;
  padding-right: 30px;
}

.swt-ionev-table.missed-opportunities .swt-ionev-table-header tr th {
  background-color: var(--ionev-red);
  padding-left: 30px;
  padding-right: 30px;
}

.swt-ionev-table .ionev-table-header-long {
  display: inline-block;
}

.swt-ionev-table .ionev-table-header-date {
  margin-left: 50px;
}

.swt-ionev-table .swt-ionev-table-header tr th {
  background-color: var(--ionev-blue);
  padding-left: 30px;
  padding-right: 30px;
}

/* .swt-ionev-table tbody > tr.even:hover > td,
.swt-ionev-table tbody > tr.hover > td,
.swt-ionev-table tbody > tr.odd:hover > td,
.swt-ionev-table tbody > tr:hover > td {
  text-decoration: underline;
  color: var(--bluish);
} */

.swt-ionev-table tbody > tr.even:hover > td a,
.swt-ionev-table tbody > tr.hover > td a,
.swt-ionev-table tbody > tr.odd:hover > td a,
.swt-ionev-table tbody > tr:hover > td a {
  text-decoration: underline;
  color: var(--bluish);
}

.swt-ionev-table tr:nth-child(odd) {
  background-color: var(--ionev-light-grey);
}

.swt-ionev-table tr:nth-child(even) {
  background-color: #ffffff;
}

.swt-subheading.table {
  width: 395px;
  height: 22px;
  opacity: 0.9;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.1px;
  color: var(--charcoal-grey);
}

.swt-ionev.tablesorter-default tr.odd > td.highlighted {
  background-color: rgb(212, 227, 227);
}

.swt-ionev.tablesorter-default tr.even > td.highlighted {
  background-color: rgb(221, 236, 236);
}

.swt-ionev.tablesorter-default tr.even > td.left-align.underlined,
.swt-ionev.tablesorter-default tr.odd > td.left-align.underlined {
  color: var(--bluish);
  text-decoration: underline;
}

.swt-ionev-table th,
.swt-ionev-table td,
.swt-nav-buttons {
  cursor: default;
}

.swt-ionev-search-input {
  width: 135px;
  height: 20px;
  border: 1px solid var(--medium-grey);
  border-radius: 6px;
  background-color: var(--white-two);
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: var(--battleship-grey);
  margin-top: 5px;
}

.swt-sort-arrows {
  padding-left: 6px;
}

.swt-sort-arrows .swt-sort-arrow-asc {
  padding-bottom: 5px;
}

.swt-ionev-table-filter {
  height: 29px;
}

/* .swt-ionev-app-content {
  text-align: center;
  margin-top: 50px;
  margin-left: 32px;
} */

.swt-ionev-table-wrapper {
  text-align: center;
  /* max-width: 90%; */
}

.swt-ionev-table-wrapper-align {
  display: inline-block;
  max-width: 85%;
}

.ionev-table-additional {
  text-align: right;
  font-size: 12px;
  /* padding-right: 30px; */
  color: #000;
  padding-bottom: 10px;
}

.ionev-table-additional span {
  display: block;
}

/* TCO Dash Graph */
.swt-ionev-tco-graph-wrapper {
  display: inline-block;
  margin-bottom: 50px;
}

.swt-ionev-tco-graph-content {
  display: inline-block;
  width: 400px;
  height: 300px;
  background-color: #e8e9ea;
  position: relative;
  padding-top: 10px;
  padding-bottom: 15px;
}

/* Landing page graphs */
/* .swt-ionev-graph-wrapper {
  display: inline-block;
  margin-bottom: 50px;
}

.ionev-graph-title {
  font-size: 13;
  font-weight: bold;
  color: #4a4a4a;
}

.swt-ionev-graph-wrapper .swt-ionev-graph-content {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  height: 600px;
  grid-template-rows: 1fr 1fr;
}

.swt-ionev-chart-1,
.ionev-subheader-1 {
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: -0.1px;
  text-align: center;
  color: #4a4a4a;
  padding-top: 7px;
}

.ionev-subheader-1 {
  text-align: left;
  padding-bottom: 5px;
}

.swt-ionev-chart-1.lhs {
  padding-bottom: 30px;
}

.swt-ionev-graph-wrapper .swt-ionev-graph-lhs,
.swt-ionev-graph-wrapper .swt-ionev-graph-rhs {
  display: inline-block;
  width: 400px;
  height: 300px;
  background-color: #e8e9ea;
  position: relative;
}

.swt-ionev-graph-lhs-container {
  padding-top: 10px;
}

.swt-ionev-graph-rhs-container {
  padding-top: 10px;
  height: 270px;
}

.swt-ionev-summary-graph-wrapper {
  display: inline-block;
} */

/* Map */
.swt-ionev-map-container {
  text-align: center;
}

.swt-ionev-map-container .swt-ionev-map-wrapper {
  max-width: 124rem;
}

.swt-ionev-map-container .swt-ionev-map-wrapper .swt-ionev-map-header {
  width: 1100px;
  height: 220px;
  background-color: #dfe6ed;
  display: inline-block;
  text-align: left;
}

.swt-ionev-map-container .swt-ionev-map-wrapper .swt-ionev-map-header-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 11px 8px 0 8px;
}

/* Map type selector buttons */
.swt-ionev-map-container
  .swt-ionev-map-wrapper
  .swt-ionev-map-header-content
  .swt-ionev-map-type-selector
  div {
  display: inline-block;
  padding-top: 6px;
  height: 28px;
  border: solid 1px var(--medium-grey);
  background-color: var(--white-two);
  cursor: pointer;
}

.swt-ionev-map-container
  .swt-ionev-map-wrapper
  .swt-ionev-map-header-content
  .swt-ionev-map-type-selector
  .ionev-map-type-location {
  padding-left: 10px;
  width: 70px;
  border-radius: 6px 0 0 6px;
}

.swt-ionev-map-container
  .swt-ionev-map-wrapper
  .swt-ionev-map-header-content
  .swt-ionev-map-type-selector
  .ionev-map-type-events {
  padding-left: 16px;
  width: 64px;
  border-radius: 0 6px 6px 0;
  border-left: none;
}

.swt-ionev-map-container
  .swt-ionev-map-wrapper
  .swt-ionev-map-header-content
  .swt-ionev-map-type-selector
  div.selected {
  background-color: var(--ionev-light-grey);
}

.swt-ionev-map-container .swt-ionev-map-wrapper .swt-ionev-map-vcl-selector {
  margin-top: 10px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px var(--battleship-grey);
  background-color: var(--white-two);
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 4px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-checkbox
  .swt-checkbox {
  display: none;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-checkbox
  .swt-checkbox {
  display: none;
  width: 20px;
  height: 20px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-checkbox.checked {
  border: none;
  margin-right: 14px;
  margin-bottom: 6px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-checkbox.checked
  .swt-checkbox {
  display: block;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-label {
  display: inline-block;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.06px;
  color: var(--battleship-grey);
  vertical-align: top;
}

/* CONSOLIDATE this - duplicates above except size */
.swt-ionev-map-container .swt-ionev-map-header .swt-ionev-map-asterisk {
  display: inline-block;
  height: 20px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.06px;
  color: var(--battleship-grey);
  vertical-align: top;
  padding-top: 10px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-lhs
  .swt-ionev-map-label {
  margin-top: 8px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-lhs
  .swt-ionev-map-label
  p {
  padding-bottom: 8px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-stats
  .swt-ionev-map-stats-line {
  padding-bottom: 3px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-label
  .swt-ionev-map-marker {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-label
  .swt-ionev-map-marker.missed-op-fleet {
  background-color: var(--ionev-red);
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-label
  .swt-ionev-map-marker.missed-op-pub {
  background-color: rgb(228, 131, 25);
}

.swt-ionev-map-container
  .swt-ionev-map-header
  .swt-ionev-map-header-rhs
  .swt-ionev-map-label
  .swt-ionev-map-marker.charge-inst {
  background-color: var(--ionev-green);
}

.swt-ionev-map-container .swt-ionev-legend {
  background-color: var(--ionev-light-grey);
  padding: 0 15px 10px 10px;
  margin: 0;
  border-top-right-radius: 6px;
  width: auto;
}

.ionev-legend-item {
  justify-items: left;
  text-align: left;
}

.swt-ionev-map-container .swt-ionev-legend-marker {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.swt-ionev-map-container .swt-ionev-legend-marker.large {
  height: 9px;
  width: 9px;
}

.swt-ionev-map-container .swt-ionev-legend-marker.medium {
  height: 8px;
  width: 8px;
}

.swt-ionev-map-container .swt-ionev-legend-marker.small {
  height: 7px;
  width: 7px;
}

/* Style markers */
.swt-ionev-map-container .swt-ionev-legend-marker.charge.large {
  background-color: rgba(47, 149, 120, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.charge.medium {
  background-color: rgba(53, 166, 125, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.charge.small {
  background-color: rgba(73, 174, 138, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mf.large {
  background-color: rgba(207, 79, 79, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mf.medium {
  background-color: rgba(231, 88, 88, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mf.small {
  background-color: rgba(233, 104, 104, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mp.large {
  background-color: rgba(226, 118, 0, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mp.medium {
  background-color: rgba(228, 131, 25, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.mp.small {
  background-color: rgba(231, 145, 50, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.loc.large {
  background-color: rgba(78, 135, 163, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.loc.medium {
  background-color: rgba(87, 151, 182, 1);
}

.swt-ionev-map-container .swt-ionev-legend-marker.loc.small {
  background-color: rgba(103, 161, 189, 1);
}

/* Hide legend markers unless type selected */
.swt-ionev-map-container .swt-ionev-legend span {
  display: none;
}

.swt-ionev-map-container .swt-ionev-legend .swt-ionev-legend-marker.loc {
  display: inline-block;
}

.swt-ionev-map-container
  .swt-ionev-legend.charge
  .swt-ionev-legend-marker.charge {
  display: inline-block;
}

.swt-ionev-map-container
  .swt-ionev-legend.missed-fleet
  .swt-ionev-legend-marker.mf {
  display: inline-block;
}

.swt-ionev-map-container
  .swt-ionev-legend.missed-pub
  .swt-ionev-legend-marker.mp {
  display: inline-block;
}

.swt-ionev-map {
  height: 570px;
  width: 1100px;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.swt-ionev-button-clear-map,
.swt-ionev-button-reset-map {
  border-radius: 4px;
  border: solid 1px #979a9e;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #4a4a4a;
  margin-top: 15px;
  display: inline;
  cursor: pointer;
}

/* Pie Graph */
.swt-ionev-summary-graph-explanation {
  font-size: 11px;
  text-align: right;
  margin-right: 10px;
  color: #4a4a4a;
}


/* Table header */
.swt-ionev-table-header {
  text-align: left;
}

/* Settings page */
.ionev-settings-header.ionev-h2 {
  text-align: center;
}

.ionev-charge-loc-form-wrapper {
  text-align: center;
  margin: 0 20%;
  border-bottom: 1px solid #4a4a4a;
  padding: 20px 0;
}

.ionev-charge-loc-form-wrapper.first {
  border-top: 1px solid #4a4a4a;
}

.ionev-charge-loc-form-content {
  display: inline-block;
}

.ionev-charge-form-error-wrapper {
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: left;
}

.ionev-charge-loc-form-error {
  color: var(--ionev-red);
}

.ionev-charge-loc-form-error.header {
  font-weight: bold;
  padding-bottom: 5px;
}

.ionev-charge-form-error-list {
  padding-top: 10px;
}

/* is this doing aything */
.ionev-charge-loc-form {
  margin-top: 20px;
}

.ionev-charge-loc-wrapper {
  margin-bottom: 10px;
}

.ionev-charge-loc-wrapper .ionev-loc-details.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  text-align: left;
}

.ionev-charge-loc-wrapper .ionev-loc-details input {
  width: 250px;
  font-size: 14px;
  color: var(--charcoal-grey);
}

.ionev-charge-loc-wrapper .ionev-loc-site {
  margin-top: 20px;
}

.ionev-charge-loc-wrapper .ionev-loc-site input {
  width: 536px;
  font-size: 14px;
  color: var(--charcoal-grey);
}

.ionev-charge-loc-wrapper .ionev-charge-loc-label {
  text-align: left;
  padding: 5px 0;
}

.ionev-charge-loc-wrapper .ionev-charge-loc-sublabel {
  font-size: 14px;
  text-align: left;
  padding-bottom: 4px;
}

.ionev-location-type-selector.ionev-selector-1 {
  width: 256px;
  height: 28px;
  border-radius: 6px;
  background-color: var(--white-two);
  cursor: pointer;
}

.ionev-charge-loc-form-wrapper .ionev-charge-form-submit {
  margin-right: 10px;
}

/* No match page */
.swt-ionev-no-match {
  margin-top: 30px;
}

/* MAP CLUSTER PLUGIN */
/* .marker-cluster-custom {
} */

/* inner circle */
/* .marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
} */
.marker-cluster-custom {
  border-radius: 80px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
}
.marker-cluster-custom div {
  border-radius: 80px;
  font-size: 0.6vw;
  color: #fff;
  font-weight: 900;
}
.marker-cluster-custom.small div {
  width: 19px;
  height: 19px;
  margin-top: 3px;
  margin-left: 3px;
}
.marker-cluster-custom.small span {
  line-height: 18px;
}
.marker-cluster-custom.medium div {
  width: 24px;
  height: 24px;
  margin-top: 3px;
  margin-left: 3px;
}
.marker-cluster-custom.medium span {
  line-height: 22px;
}
.marker-cluster-custom.large div {
  width: 29px;
  height: 29px;
  margin-top: 3px;
  margin-left: 3px;
}
.marker-cluster-custom.large span {
  line-height: 27px;
}

.marker-cluster-custom.charge-opp.small {
  background-color: rgba(73, 174, 138, 0.6);
}
.marker-cluster-custom.charge-opp.small div {
  background-color: rgba(73, 174, 138, 0.8);
}
.marker-cluster-custom.charge-opp.medium {
  background-color: rgba(53, 166, 125, 0.6);
}
.marker-cluster-custom.charge-opp.medium div {
  background-color: rgba(53, 166, 125, 0.8);
}
.marker-cluster-custom.charge-opp.large {
  background-color: rgba(47, 149, 120, 0.6);
}
.marker-cluster-custom.charge-opp.large div {
  background-color: rgba(47, 149, 120, 0.8);
}

.marker-cluster-custom.missed-fleet.small {
  background-color: rgba(233, 104, 104, 0.6);
}
.marker-cluster-custom.missed-fleet.small div {
  background-color: rgba(233, 104, 104, 0.8);
}
.marker-cluster-custom.missed-fleet.medium {
  background-color: rgba(231, 88, 88, 0.6);
}
.marker-cluster-custom.missed-fleet.medium div {
  background-color: rgba(231, 88, 88, 0.8);
}
.marker-cluster-custom.missed-fleet.large {
  background-color: rgba(207, 79, 79, 0.6);
}
.marker-cluster-custom.missed-fleet.large div {
  background-color: rgba(207, 79, 79, 0.8);
}

.marker-cluster-custom.missed-public.small {
  background-color: rgba(231, 145, 50, 0.6);
}
.marker-cluster-custom.missed-public.small div {
  background-color: rgba(231, 145, 50, 0.8);
}
.marker-cluster-custom.missed-public.medium {
  background-color: rgba(228, 131, 25, 0.6);
}
.marker-cluster-custom.missed-public.medium div {
  background-color: rgba(228, 131, 25, 0.8);
}
.marker-cluster-custom.missed-public.large {
  background-color: rgba(226, 118, 0, 0.6);
}
.marker-cluster-custom.missed-public.large div {
  background-color: rgba(226, 118, 0, 0.8);
}

.marker-cluster-custom.location-activity.small {
  background-color: rgb(103, 161, 189, 0.6);
}
.marker-cluster-custom.location-activity.small div {
  background-color: rgb(103, 161, 189, 0.8);
}

.marker-cluster-custom.location-activity.medium {
  background-color: rgb(87, 151, 182, 0.6);
}
.marker-cluster-custom.location-activity.medium div {
  background-color: rgb(87, 151, 182, 0.8);
}

.marker-cluster-custom.location-activity.large {
  background-color: rgb(78, 135, 163, 0.6);
}
.marker-cluster-custom.location-activity.large div {
  background-color: rgb(78, 135, 163, 0.8);
}

.marker-cluster-custom span {
  font-size: 11px;
  line-height: 22px;
}

/* Update charge location map */
.swt-ionev-update-location-map {
  height: 300px;
}

.swt-ionev-newLoc-marker {
  background-color: var(--ionev-green);
}

/* About page */
.swt-ionev-about-wrapper .swt-ionev-about-section {
  padding-bottom: 10px;
  justify-content: center;
}

.swt-ionev-about-wrapper .swt-ionev-about-header,
.swt-ionev-error-header {
  font-size: 20px;
  /* font-weight: 'bold'; */
  color: #4a4a4a;
  padding-bottom: 5px;
}

.swt-ionev-about-wrapper .swt-ionev-assumptions-section {
  padding-top: 20px;
}

.swt-ionev-error-wrapper {
  padding-top: 10px;
}

.swt-ionev-about-wrapper .swt-ionev-about-text,
.swt-ionev-about-wrapper .swt-ionev-assumptions-value,
.swt-ionev-about-wrapper .swt-ionev-assumptions-label,
.swt-ionev-assumptions-edit,
.swt-ionev-error-text {
  font-size: 16px;
  padding-bottom: 5px;
  max-width: 55rem;
  color: var(--battleship-grey);
  margin: auto;
}

.swt-ionev-about-wrapper .swt-ionev-assumptions-label {
  font-weight: 500;
}

.swt-ionev-about-wrapper .swt-ionev-assumptions-label,
.swt-ionev-about-wrapper .swt-ionev-assumptions-value {
  display: inline-block;
  padding-right: 5px;
}

.swt-ionev-assumptions-wrapper {
  padding-top: 10px;
}

.swt-ionev-assumptions-edit {
  padding-top: 10px;
}

.swt-ionev-assumptions-edit a {
  margin-left: 3px;
}

/* Media queries */
@media only screen and (max-width: 1400px) {
  /* Header - reduce padding */
  .ionev-navigation-links {
    margin-left: 100px;
  }
   /* Body - prevent content from going under product menu */
   .swt-ionev-page-body {
    margin-left: 100px;
  }
  /* Map - reduce size */
  .swt-ionev-map-container .swt-ionev-map-wrapper .swt-ionev-map-header {
    width: 800px;
  }
  .swt-ionev-map {
    width: 800px;
    height: 456px;
  }
  .swt-ionev-map-container .ionev-map-selector-label,
  .swt-ionev-map-container .swt-ionev-map-stats-line,
  .swt-ionev-map-container .swt-ionev-map-header .swt-ionev-map-label {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1400px) {
  /* Header - Stack */
  .ionev-header-selectors {
    margin-top: 5px;
  }
  .ionev-header-date-selector-wrapper {
    display: block;
    margin-bottom: 15px;
    margin-left: 30px;
  }
  .ionev-header-date-selector-wrapper .ionev-header-selector-label,
  .ionev-header-group-selector-wrapper .ionev-header-selector-label {
    display: inline-block;
    margin-right: 10px;
  }
  .ionev-header-selectors
    .ionev-header-group-selector-wrapper
    .ionev-header-group-selector {
    width: 206px;
  }
}

@media only screen and (max-width: 1400px) {
  /* Dashboard - Stack CTA cards (2 rows) */
  .swt-ionev-cta-wrapper {
    width: 650px;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-gap: 20px 0;
    grid-template-areas:
      "one two"
      "three three";
  }
  .swt-ionev-cta-wrapper .swt-ionev-cta-card {
    margin: auto;
  }
  .swt-ionev-cta-wrapper .swt-ionev-cta-card.total-activity {
    grid-area: three;
  }
}

@media only screen and (max-width: 1200px) {
  .swt-ionev-table {
    max-width: 1000px;
  }
  .swt-ionev-map-container .swt-ionev-map-wrapper .swt-ionev-map-header-content {
    grid-template-columns: auto auto;
    grid-gap: 20px;
  }
  .ionev-header-date-picker-wrapper {
    margin-right: 20px;
  }
  /* .swt-ionev-header .ionev-header-text {
    margin-left: 39px;
  } */
  /* Dashboard - Stack everything single */
  /* .swt-ionev-cta-wrapper {
    width: 650px;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-gap: 20px 0;
    grid-template-areas:
      "one"
      "two"
      "three";
  }
  .swt-ionev-cta-wrapper .swt-ionev-cta-card.charge-inst {
    grid-area: one;
  }
  .swt-ionev-cta-wrapper .swt-ionev-cta-card.missed-op {
    grid-area: two;
  }
  .swt-ionev-cta-wrapper .swt-ionev-cta-card {
    margin: auto;
  }
  .swt-ionev-graph-wrapper .swt-ionev-graph-content {
    height: 1250px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  } */
  /* .ionev-header-navigation {
    grid-template-columns: 460px 1fr;
  } */
  .ionev-navigation-links .ionev-navigation {
    margin-right: 25px;
  }
  .ionev-navigation-links .ionev-navigation-div .swt-ionev-navigation-arrow {
    margin-right: 25px;
  }
  .ionev-header-date-selector-wrapper {
    margin-right: 10px;
    /* margin-left: 0; */
  }
  /* Header */
  /* .ionev-header-selectors .ionev-header-selector-label {
    display: none;
  } */
}

/* Change arrows */
.ionev-change-arrow,
.ionev-trend-arrow {
  width: 15px;
  padding-right: 3px;
}

.ionev-change-arrow.asc,
.ionev-change-arrow.asc {
  padding-bottom: 3px;
}

.ionev-no-change-arrow {
  padding-left: 15px;
}

.swt-ionev-landing-wrapper .ionev-change-arrow.desc {
  padding-bottom: 4px;
}

.swt-ionev-landing-wrapper .ionev-change-arrow.asc {
  padding-bottom: 4px;
}

@media only screen and (max-width: 1000px) {
  /* Header - condense */
  /* .ionev-navigation-links {
    margin-left: 40px;
  } */
}

.swt-ionev-mapbox-container {
  height: 550px;
  width: 1100px;
  z-index: 0;
  position: relative;
  margin: 0 auto 10px auto;
}
@media screen and (max-width: 1400px) {
  .swt-ionev-mapbox-container {
    width: 800px;
  }
}

.marker-popup {
  text-align: left;
  font-size: 13px;
}

.popup-btn{
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 5px;
  color: #74787d;
  font-size: 11px;
  width: 120px;
  cursor: pointer;
}

.popup-link {
  color: #0078a8;
}

.update-location-btn, .events-by-location-btn {
  cursor: pointer;
  text-decoration: underline;
}

.map-satellite-control {
  position: absolute;
  height: 30px;
  width: max-content;
  bottom: 10px;
  right: 12px;
  background-color: white;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  column-gap: 5px;
}

.swt-ionev-map-wrapper {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.ionev-update-location-map-satellite-control {
  position: absolute;
  height: 30px;
  width: 20%;
  bottom: 10px;
  right: 20px;
  background-color: white;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  column-gap: 5px;
  white-space: pre;
}

.swt-ionev-map-legend {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: auto;
  background-color: #f5f5f5;
  z-index: 100;
  font-size: 12px;
  padding: 0 15px 10px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: 6px;
}

.map-legend-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
}

.map-legend-circle-sm {
  height: 7px;
  width: 7px;
  border-radius: 7px;
  margin: 0 4px;
}
.map-legend-circle-md {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  margin: 0 4px;
}
.map-legend-circle-lrg {
  height: 9px;
  width: 9px;
  border-radius: 9px;
  margin: 0 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none; /*Removes arrow buttons on inputs for webkit-based browsers*/
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Remove arrows on Firefox */
}

.ionev-link {
  text-decoration: none;
  color: var(--bluish);
}

.ionev-link:hover {
  text-decoration: underline;
}

.swt-processing-wrapper {
  margin-top: 20px;
  margin-left: 20px;
}