@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff2')
      format('woff2'),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff')
      format('woff'),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Bold),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Bold.woff2')
      format('woff2'),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Bold.woff')
      format('woff'),
    url('https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

.sftd-application {
  justify-content: center;
  min-width: 90rem;
  position: relative;
}

.sftd-application h1 {
  font-family: OpenSans;
  font-size: 2.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #292929;
}
.sftd-application h2 {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 0.1px;
}
.sftd-application h3 {
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--smoke);
}
.sftd-application .Body-1-black {
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  text-align: center;
  color: #292929;
}
.sftd-application .Body-3 {
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--brown-grey);
}
.sftd-application .Body-2 {
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
}

:root {
  --smoke: #2c2c2e;
  --denim: #3e668c;
  --color-blue: #7bb6cd;
  --dark-coral: #cc4d4d;
  --color-yellow: #e3d25a;
  --pale-teal: #7fcd91;
  --pale-orange: rgba(247, 174, 84, 0.767);
  --light-periwinkle: #d1d9e6;
  --white: #ffffff;
  --pale-grey: #fafbfc;
  --light-grey: #e7e7e7;
  --color-light-grey: #e3e3e3;
  --grey: #cecece;
  --color-grey: #bbbbbb;
  --very-light-pink: #f3f3f3;
  --color-dark-grey: #5c5c5c;
  --pale-sky-blue: #d3e6ec;
  --seafoam-blue-51: rgba(125, 198, 214, 0.51);
  --seafoam-blue: #7dc6d6;
  --pale-teal-45: rgba(127, 205, 145, 0.45);
  font-family: 'Arimo', 'Open Sans', 'HelveticaNeue', 'Helvetica Neue', Arial,
    sans-serif;
}

.sftd-active-processing-wrapper{
  margin-left:90px;
}

.sftd-page-title {
  margin-top: 2.188rem;
  margin-left: 13.2%;
}

.sftd-Map-Page .sftd-page-title,
.sftd-Map-Page-open .sftd-page-title {
  /* background-color: var(--pale-grey); */
  margin: 0rem;
  padding-top: 2rem;
  padding-left: 14rem;
}

.sftd-Map-Page {
  margin-bottom: 0.5rem;
}

.sftd-Map-Page .sftd-updateMessage,
.sftd-Map-Page-open .sftd-updateMessage {
  position: absolute;
  color: var(--dark-coral);
  width: 41rem;
  margin-left: 13rem;
  font-size: 0.8rem;
  margin-top: 0rem;
  padding-top: 0rem;
}

.sftd-ttl-line {
  width: 62.5%;
  height: 0.01px;
  margin-left: 13.75rem;
  border: solid 1px var(--light-periwinkle);
}

/* NAV MENU */

.sftd-nav-settings-wrapper {
  min-width: 90rem;
  height: 13.063rem;
}

.sftd-nav_bg {
  min-width: 90rem;
  height: 7rem;
  box-shadow: 0 0 4px 3px #ecf0f3;
  background-color: var(--white);
}

.sftd-product_name {
  width: 9rem;
  height: 2.313rem;
  font-family: OpenSans;
  font-size: 2.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: var(--smoke);
  margin-left: 3.75%;
  margin-top: 2.2138rem;
  display: inline-block;
}

.sftd-nav_bg .sftd-ttl-image {
  width: 8rem;
  height: 2.5rem;
  margin-left: 3.75%;
  margin-top: 1.98rem;
  display: inline-block;
}

.sftd-nav_bg .sftd-navigation-icon-wrapper {
  position: absolute;
  display: inline-block;
  min-width: 69rem;
  top: 3.4rem;
  margin-left: 6.688%;
  background-color: var(--white);
}

.navbar_link_home {
  margin-left: 15%;
}
.navbar_link_data {
  margin-left: 21.875%;
}

.navbar_link_map {
  margin-left: 21.062%;
}

.navbar_link_vehicle {
  margin-left: 21.875%;
}

.sftd-nav_bg .sftd-navigation-icon-wrapper .sftd-nav-icon {
  float: left;
  cursor: pointer;
}

.sftd-nav-image#home {
  width: 1.813rem;
  height: 1.375rem;
}

.sftd-nav-image#dataPage {
  width: 1.875rem;
  height: 1.438rem;
}

.sftd-nav-image#map {
  width: 1.875rem;
  height: 1.438rem;
}

.sftd-nav-image#vehicles {
  width: 2.312rem;
  height: 1.688rem;
}

/* NAV MENU END  */

/* USER SETTINGS */

/* DASHBOARD */
.swt-main .sftd-user-settings {
  position: relative;
  margin-top: 0.4rem;
  min-width: 90rem;
  height: 5.5rem;
  background-color: var(--white);
  z-index: 9;
}

.sftd-user-settings .sftd-day-selector {
  float: left;
  width: 10.313rem;
  margin-left: 1%;
  width: 12.312rem;
}

.sftd-user-settings .sftd-day {
  margin-top: 2.188rem;
  float: left;
  width: 1rem;
  height: 1.375rem;
  margin-left: 0.688rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.sftd-day#selected {
  color: var(--smoke);
}
.sftd-day#unselected {
  color: var(--light-periwinkle);
}

.sftd-date-selector,
.sftd-date-selector-single,
.sftd-time-selector,
.sftd-location-selector,
.sftd-group-selector,
.sftd-class-selector,
.sftd-details-selector,
.sftd-timeDelta-selector,
.sftd-timeDelta-selector-des {
  margin-top: 1.75rem;
  border-radius: 7px;
  float: left;
  border: solid 1px var(--light-periwinkle);
  justify-content: center;
}
.sftd-date-selector-single,
.sftd-date-selector {
  position: relative;
  margin-left: 1%;
  width: 15.438rem;
  height: 2.25rem;
  border-radius: 7px;
}

.sftd-date-selector-single {
  margin-left: 31%;
}

.sftd-time-selector {
  margin-left: 1%;
  width: 7.938rem;
  height: 2.25rem;
  /* cursor: pointer; */
  background-color: rgb(255, 255, 255);
}

.sftd-location-selector {
  margin-left: 5.688%;
  width: 7.625rem;
  height: 2.25rem;
  cursor: pointer;
}

.sftd-group-selector {
  margin-left: 1%;
  width: 5.313rem;
  height: 2.25rem;
  /* cursor: pointer; */
  background-color: rgb(255, 255, 255);
}

.sftd-class-selector {
  margin-left: 1%;
  width: 4.938rem;
  height: 2.25rem;
  cursor: pointer;
}
.sftd-timeDelta-selector,
.sftd-timeDelta-selector-des {
  margin-left: 1%;
  width: 4.188rem;
  height: 2.25rem;
  cursor: pointer;
  border-radius: 7px;
}

.sftd-timeDelta-selector {
  border: solid 1px var(--light-periwinkle);
}

.sftd-timeDelta-selector-des {
  border: solid 1px rgba(206, 205, 205, 0.973);
}

.sftd-time-selector#clicked,
.sftd-timeDelta-selector#clicked,
.sftd-details-selector#clicked,
.sftd-location-selector#clicked,
.sftd-group-selector#clicked,
.sftd-class-selector#clicked {
  border-radius: 7px;
  box-shadow: inset 3px 3px 5px 0 var(--light-periwinkle);
  background-color: #ecf0f3;
}

.sftd-details-selector {
  margin-left: 2%;
  width: 5.313rem;
  height: 2.25rem;
  cursor: pointer;
}

.sftd-date {
  position: absolute;
  margin-top: 0.5rem;
  left: 7rem;
}
.sftd-datePicker-start {
  margin-left: 0.8rem;
  display: relative;
  z-index: 1000;
}
.sftd-datePicker-end {
  margin-left: 1.8rem;
  display: relative;
  z-index: 1000;
}

.sftd-singleVcl-ttl {
  text-align: left;
  width: 62.313rem;
  /* height: 6.75rem; */
  margin: 0rem;
  margin-top: 1rem;
  margin-left: 13.875%;
  font-family: OpenSans;
  font-size: 2.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: var(--pale-teal);
}

.sftd-singleVcl-ttl2 {
  text-align: left;
  width: 62.313rem;
  margin: 0rem;
  margin-left: 13.875%;
  font-family: OpenSans;
  font-size: 1.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: var(--pale-teal);
}
.sftd-datePicker-start .react-datepicker-wrapper,
.sftd-datePicker-end .react-datepicker-wrapper,
.sftd-datePicker-start .react-datepicker__input-container,
.sftd-datePicker-end .react-datepicker__input-container,
.sftd-datePicker-start .react-datepicker__input-container input,
.sftd-date-selector .react-datepicker__input-container input,
.sftd-date-selector-single .react-datepicker__input-container input {
  border: none;
  width: 5.813rem;
  background-color: rgba(255, 255, 255, 0);
  height: 2.25rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  color: var(--smoke);
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.sftd-timeDelta-selector-des .sftd-timeDelta-title,
.sftd-timeDelta-selector .sftd-timeDelta-title,
.sftd-location-selector .sftd-location-title,
.sftd-group-selector .sftd-group-title,
.sftd-class-selector .sftd-class-title,
.sftd-details-selector .sftd-details-title {
  margin: 0px;
  margin-top: 0.3rem;
  margin-left: 0.812rem;
  height: 2.25rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--smoke);
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.sftd-timeDelta-selector-des .sftd-timeDelta-title,
.sftd-timeDelta-selector .sftd-timeDelta-title {
  width: 2.563rem;
}

.sftd-timeDelta-selector-des .sftd-timeDelta-title {
  color: rgba(206, 205, 205, 0.973);
}

.sftd-group-selector .sftd-group-title {
  width: 3.688rem;
  color: rgb(206, 205, 205);
}
.sftd-class-selector .sftd-class-title {
  width: 3.313rem;
}
.sftd-details-selector .sftd-details-title {
  width: 3.688rem;
}
.sftd-location-selector .sftd-location-title {
  width: 7rem;
  margin-left: 0.5rem;
}

/* Time Selector */

.sftd-time-btn-ttl {
  margin: 0rem;
  padding-top: 0.5rem;
  color: rgb(206, 205, 205);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.sftd-time-modal {
  position: relative;
  width: 18.25rem;
  height: 4.938rem;
  margin-top: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--light-grey);
  background-color: var(--white);
  z-index: 5;
}

.sftd-time-input {
  /* width: 5.25rem; */
  /* height: 1.355rem; */
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #292929;
}

.sftd-time-modal-title {
  width: 3.438rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
}
.sftd-time-modal:focus {
  width: 3.438rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
}

.sftd-time-selector .sftd-startTime,
.sftd-time-selector .sftd-stopTime {
  position: relative;
  background-color: rgba(255, 255, 255, 0);
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--smoke);
  -webkit-appearance: none;
  width: 6.875rem;
  height: 1.938rem;
  border-radius: 7px;
  border: solid 1px var(--light-periwinkle);
}

.sftd-start-time {
  position: absolute;
  left: 1.5rem;
  top: 1rem;
}
.sftd-stop-time {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}

/* Time Delta Selector */
.sftd-timeDeltaSelector-options {
  z-index: 3000;
  padding-top: 0.05rem;
  position: relative;
  width: 12.625rem;
  height: 17.75rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--light-grey);
  background-color: var(--white);
}

.sftd-timeDelta-btn {
  margin-top: 0.875rem;
  margin-left: 1.188rem;
  width: 10.313rem;
  height: 2.25rem;
  border-radius: 7px;
  border: solid 1px var(--light-periwinkle);
  cursor: pointer;
}

.sftd-timeDelta-btn#clicked {
  width: 10.313rem;
  height: 2.25rem;
  border-radius: 7px;
  box-shadow: inset 3px 3px 5px 0 var(--light-periwinkle);
  background-color: #ecf0f3;
}

.sftd-timeDelta-btn .timeDelta-ttl {
  width: 8.688rem;
  height: 2.25rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--smoke);
  margin-top: 0.5rem;
  margin-left: 0.812rem;
}

/*  Location Selector */

.sftd-locations-modal {
  position: relative;
  z-index: 10000;
  width: 36.313rem;
  /* max-height: 43.75rem; */
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--light-grey);
  background-color: var(--white);
}

.sftd-locations-modal .sftd-selectAllBtn {
  display: grid;
  grid-area: selectButton;
  margin-left: 10rem;
  width: 11rem;
  height: 1.6rem;
  margin-bottom: 1.25rem;
  border-radius: 8px;
  font-size: 0.86rem;
  border-radius: 7px;
  border: solid 1px var(--light-periwinkle);
  background-color: white;
  padding-top: 0.25rem;
  cursor: pointer;
}

.sftd-locations-drpdown-wrapper {
  width: 32.625rem;
  position: relative;
  height: fit-content;
  margin-left: 1.75rem;
  display: grid;
  grid-template-areas:
    'search search'
    'selectButton selectButton'
    'titleL titleR'
    'unselected selected';
}
.sftd-locations-drpdown-wrapper .sftd-search {
  display: grid;
  grid-area: search;
  height: 5rem;
  width: 10rem;
}

.sftd-locations-drpdown-wrapper .sftd-locations-unselected {
  display: grid;
  grid-area: unselected;
  max-height: 30rem;
  height: fit-content;
  overflow-y: scroll;
  width: 16rem;
  display: inline-block;
  margin-top: 1rem;
  margin-bottom: 2rem;
  vertical-align: top;
}
.sftd-locations-none {
  color: grey;
  font-size: 0.8rem;
  margin-left: 2rem;
}

.sftd-locations-drpdown-wrapper .sftd-locations-unselected .item,
.sftd-locations-drpdown-wrapper .sftd-locations-selected .item {
  width: 15rem;
  height: 2.938rem;
  border-radius: 7px;
  margin-bottom: 0.688rem;
  padding: 0.35rem;
}
.sftd-locations-drpdown-wrapper .sftd-locations-selected .item {
  background-color: rgba(125, 198, 214, 0.5);
}
.sftd-locations-drpdown-wrapper .sftd-locations-unselected .item {
  background-color: #ecf0f3;
}

.sftd-locations-drpdown-wrapper .sftd-locations-selected {
  display: grid;
  grid-area: selected;
  max-height: 30rem;
  height: fit-content;
  width: 16rem;
  overflow-y: scroll;
  display: inline-block;
  vertical-align: top;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.sftd-locations-container {
  height: 11rem;
  overflow: scroll;
}

.sftd-container-ttl#search,
.sftd-container-ttl#selected {
  margin: 0px;
}

.sftd-container-ttl#search {
  display: grid;
  grid-area: titleL;
}
.sftd-container-ttl#selected {
  right: 1rem;
  display: grid;
  grid-area: titleR;
}
.sftd-container-ttl {
  width: 14.625rem;
  height: 1.563rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
}
.sftd-searchbar {
  width: 32.625rem;
  height: 2.75rem;
  line-height: 2.75rem;
  border-radius: 15px;
  border: solid 1px #e4e8ef;
  background-color: #ecf0f3;
  margin-bottom: 0.8rem;
}
.sftd-searchbar:focus {
  margin-bottom: 1.18rem;
  width: 32.625rem;
  height: 2.75rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  padding-left: 0.812rem;
  color: var(--smoke);
  outline: none;
}
.sftd-searchbar::-ms-input-placeholder {
  padding-left: 0.812rem;
  width: 14.938rem;
  height: 1.375rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #b0bbc5;
}
.sftd-searchbar:-ms-input-placeholder {
  padding-left: 0.812rem;
  width: 14.938rem;
  height: 1.375rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #b0bbc5;
}
.sftd-searchbar::placeholder {
  padding-left: 0.812rem;
  width: 14.938rem;
  height: 1.375rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #b0bbc5;
}

/*  Group Selector */
.sftd-group-modal {
  position: relative;
  z-index: 10000;
  width: 15.75rem;
  height: 6.188rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--light-grey);
  background-color: var(--white);
}

.sftd-locations-modal-title {
  margin: 0px;
  margin-left: 1.75rem;
  padding-top: 0.875rem;
  padding-bottom: 0.938rem;
  width: 7.75rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
}
/* Class Selector */
.sftd-class-modal {
  position: relative;
  z-index: 10000;
  text-align: left;
  width: 15.813rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--light-grey);
  background-color: var(--white);
}

.sftd-group-modal,
.sftd-class-modal #group,
.sftd-class-modal #all {
  margin-left: 1.062rem;
  margin-top: 1.015rem;
  width: 0.938rem;
  height: 0.938rem;
}

.sftd-class-modal [id^="vcl"] {
    margin-left: 1.062rem;
    margin-top: 1.015rem;
    width: 0.938rem;
    height: 0.938rem;
}

.stfd-class-modal-li{
  margin-left: 1.062rem;
  margin-top: 1.015rem;
  width: 0.938rem;
  height: 0.938rem;
}

.sftd-radio-custom-loc,
.sftd-radio-custom-loc:checked {
  width: 0.938rem;
  height: 0.938rem;
}
.sftd-locations-label {
  margin-left: 0.3rem;
}

.sftd-class-modal #clear {
  margin-left: 1.062rem;
  margin-top: 0.9rem;
  width: 0.938rem;
  height: 0.938rem;
}

.sftd-radio-custom-loc,
.sftd-radio-custom {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../images/checkbox_unselected.png');
  background-size: 0.938rem 0.938rem;
  background-color: white;
}

.sftd-radio-custom-loc:checked,
.sftd-radio-custom:checked {
  background-image: url('../../images/checkbox_selected_hover.png');
  background-size: 0.938rem 0.938rem;
  background-color: white;
}
.sftd-radio-custom-loc:hover,
.sftd-radio-custom:hover {
  background-image: url('../../images/checkbox_selected.png');
  background-size: 0.938rem 0.938rem;
  background-color: white;
}

.sftd-radio-custom-clear {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../../images/unselected_clear_btn.png');
  background-size: 0.938rem 0.938rem;
  background-color: white;
}

.sftd-radio-custom-clear:hover {
  background-image: url('../../images/hover_clear_btn.png');
  background-size: 0.938rem 0.938rem;
}

.sftd-group-modal #label,
.sftd-class-modal #label {
  width: 5.313rem;
  height: 1.313rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #292929;
  margin-left: 0.438rem;
}
/* Details Page */
.sftd-details-expanded {
  /* float: top; */
  /* display: inline; */
  position: relative;
  margin-top: 0rem;
  min-width: 90rem;
  height: 15.313rem;
  box-shadow: 0 3px 5px 0 #e4e8ef;
  background-color: var(--pale-grey);
  z-index: 1;
  margin: 0rem;
}
.sftd-details {
  margin-top: 0rem;
  min-width: 90rem;
  height: 15.313rem;
  box-shadow: 0 3px 5px 0 #e4e8ef;
  background-color: var(--pale-grey);
  white-space: nowrap;
  display: grid;
  z-index: 2;
  /* grid-template-areas: ; */
}

.sftd-details-title {
  width: 13.375rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--smoke);
}
.sftd-details .sftd-current-selection .sftd-details-item,
.sftd-details .sftd-total-fleet .sftd-details-item {
  margin: 0rem;
  width: 13.375rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #292929;
  margin-top: 1em;
}

.sftd-details .sftd-details-current .sftd-details-item,
.sftd-details .sftd-details-projected .sftd-details-item {
  margin-top: 0rem;
  width: 17.688rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  color: #292929;
  margin-top: 1em;
}
.sftd-details .sftd-details-projected .sftd-projected,
.sftd-details .sftd-current-selection .sftd-selected {
  float: left;
}

.sftd-details .sftd-selected {
  content: '';
  display: table;
  clear: both;
  width: 35rem;
}

.sftd-details .sftd-selected .sftd-details-title {
  margin-top: 5px;
  margin-bottom: 0.25rem;
  margin-left: 0rem;
}
.sftd-details .sftd-selected .column1 {
  float: left;
}
.sftd-details .sftd-selected .column2 {
  margin-left: 21.5rem;
}
.sftd-details .sftd-selected .column2 .sftd-details-item {
  margin-left: 5rem;
}
.sftd-details .sftd-selected .sftd-details-item {
  margin: 9px;
  margin-left: 0rem;
}

.sftd-details .sftd-details-item .sftd-smallTxt {
  font-size: 11px;
  color: grey;
}

.sftd-details .sftd-current-selection .sftd-total-fleet {
  float: left;
  margin-left: 13.375rem;
}
.sftd-details .sftd-details-projected .sftd-current {
  margin-left: 26.875rem;
}

.sftd-details .sftd-details-projected {
  margin-left: 26.875rem;
  margin-top: 3rem;
  height: 7rem;
}

.sftd-details .sftd-current-selection {
  margin-left: 27rem;
  height: 2rem;
}

.sftd-details .sftd-dividing-line {
  margin-top: 0.8rem;
  margin-left: 17%;
  width: 66.313%;
  height: 0.02rem;
  border: solid 1px var(--light-periwinkle);
}

.sftd-setting-refresh {
  position: relative;
  float: left;
  width: 1.938rem;
  height: 1.938rem;
  margin-left: 2.25rem;
  margin-top: 1.8rem;
  cursor: pointer;
  background-image: url('../../images/updateButton.png');
  background-size: 1.938rem 1.938rem;
  cursor: pointer;
}

.sftd-setting-refresh:hover,
.sftd-setting-refresh#active {
  background-image: url('../../images/updateButton_hover.png');
}

.sftd-setting-refresh:active {
  background-image: url('../../images/updateButton_click.png');
}

/* Setting Reset */
.sftd-setting-reset {
  position: relative;
  float: left;
  width: 1.938rem;
  height: 1.938rem;
  margin-left: 2.25rem;
  margin-top: 1.8rem;
  cursor: pointer;
  background-image: url('../../images/clear_unselected_sml.png');
  background-size: 1.938rem 1.838rem;
  background-repeat: no-repeat;
  cursor: pointer;
}

.sftd-setting-reset:hover {
  background-image: url('../../images/clear_hover_sml.png');
}

.sftd-setting-reset:active {
  background-image: url('../../images/clear_selected_sml.png');
}

.sftd-setting-line {
  position: absolute;
  top: 5.5rem;
  width: 100%;
  margin-bottom: 0px;
  border: 0.01rem solid #e4e8ef;
}

.sftd-landing-page {
  /* background-color: var(--pale-grey); */
  min-width: 90rem;
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;
}

.sftd-client-name {
  margin: 0px;
  margin-left: 0.75%;
  font-size: 1.2rem;
  color: var(--color-grey);
  display: inline-block;
  min-width: 2rem;
}

.sftd-noLocationMsg {
  color: grey;
  margin-left: 28%;
  padding-top: 5rem;
}
.sftd-top5-item {
  display: inline-block;
}
.sftd-vcl-lst-utilization-wrpr .sftd-top5-ul-id,
.sftd-vcl-lst-utilization-wrpr .sftd-top5-ul,
.sftd-vcl-lst-utilization-wrpr .sftd-top5-address {
  width: 29.188rem;
  /* height: 1.75rem; */
  font-family: OpenSans;
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--smoke);
  padding: 0px;
  margin: 0px;
}

.sftd-vcl-lst-utilization-wrpr .sftd-top5-address {
  color: var(--color-grey);
  margin-left: 7rem;
  float: right;
}

.sftd-vcl-lst-utilization-wrpr .sftd-top5-ul {
  float: right;
  font-weight: 600;
  color: #292929;
}

.sftd-vcl-lst-utilization-wrpr .lst-util-vcls,
.sftd-sub-title {
  color: var(--color-grey);
  margin: 0rem;
  font-size: 0.75rem;
}
.sftd-vcl-lst-utilization-wrpr .sftd-top5-ul-id {
  float: right;
  color: #959ba3;
}
.sftd-vcl-lst-utilization-wrpr .sftd-utl-line {
  margin-left: 0.594rem;
  width: 88.313%;
  height: 0.01px;
  border: solid 1px var(--light-grey);
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.sftd-vcl-lst-utilization-wrpr .sftd-top5-perc {
  float: left;
  width: 4.513rem;
  height: 1.063rem;
  font-family: OpenSans;
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: var(--smoke);
  margin: 0px;
  padding: 0px;
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}
.sftd-yAxis-label {
  position: absolute;
  left: -6%;
  bottom: 52%;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 14.25rem;
  height: 1.5rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #b0bbc5;
}

.sftd-landing-wrpr {
  display: grid;
  grid-template-areas:
    'vclUtil lstUtil'
    'visual visual';
  margin-top: 0.1rem;
  grid-template-columns: 40.375% 40.375%;
  grid-template-rows: 18rem 36rem;
  grid-column-gap: 2.188rem;
  margin-left: 13%;
  padding-bottom: 1.938rem;
  padding-top: 0.812rem;
  min-width: 70rem;
}

.sftd-vcl-utilization-wrpr {
  /* display: grid; */
  grid-area: vclUtil;
  float: left;
  height: 19.825rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  background-color: var(--pale-grey);
}
.sftd-vcl-lst-utilization-wrpr {
  position: relative;
  grid-area: lstUtil;
  float: left;
  height: 19.825rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  background-color: var(--pale-grey);
  overflow: hidden;
}
.sftd-landing-vsl-wrpr {
  position: relative;
  margin-top: 3.2rem;
  display: grid;
  grid-area: visual;
  min-width: 62.313rem;
  width: 100%;
  height: 33.188rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  background-color: var(--pale-grey);
  margin-bottom: 2rem;
}
.sftf-vcl-wrpr {
  margin: 0rem;
}

.sftd-vcl-wrpr#selected {
  background-color: rgba(228, 225, 225, 0.411);
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  margin-bottom: 0.5rem;
}
.sftd-xAxis-label {
  position: absolute;
  width: 16.25rem;
  height: 1.5rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #b0bbc5;
  left: 40%;
  padding-bottom: 2rem;
}
.sftd-vcl-lst-utilization-wrpr .sftd-vcl-utilization-title,
.sftd-vcl-utilization-wrpr .sftd-vcl-utilization-title {
  width: 30.375rem;
  height: 2.413rem;
  text-shadow: 0 2px 4px #e4e8ef;
  font-family: OpenSans;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--seafoam-blue);
  margin-left: 0.5rem;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
.sftd-vsl-ttl {
  text-align: left;
  width: 52.125rem;
  height: 2.75rem;
  font-family: OpenSans;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #292929;
  margin-left: 1.438rem;
  margin-top: 0.625rem;
  margin-bottom: 0rem;
}

.sftd-metric-ttls {
  color: #959ba3;
  font-size: 12px;
  font-weight: 2;
}

/* MAP */
.sftd-map-container {
  padding-top: 2.438rem;
  min-width: 90rem;
  height: 40.438rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  /* background-color: var(--pale-grey); */
}

.sftd-map-container .sftd-map-controller {
  width: 100%;
  height: 90%;
}

#toggle-label-lh-unselected,
#toggle-label-lh-selected {
  padding-right: 2rem;
}

#toggle-label-rh-unselected,
#toggle-label-rh-selected {
  padding-left: 2rem;
}

#toggle-label-rh-selected,
#toggle-label-lh-selected {
  color: var(--smoke);
}

#toggle-label-rh-unselected,
#toggle-label-lh-unselected {
  color: #959ba3;
}

.map-toggles {
  padding-top: 1.5rem;
  padding-left: 48%;
  /* background-color: var(--pale-grey); */
}

.sftd-map-container .sftd-map-controller .map-toggles #toggle-label {
  width: 11.188rem;
  height: 1.25rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
  padding-left: 0.5rem;
}

.react-switch-bg {
  margin-top: 1rem;
}

.sftd-map-wrapper {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
}

.sftd-map {
  min-width: 62.063rem;
  width: 68%;
  height: 35.313rem;
  margin-left: 13.938%;
  border: 1px solid black;
}

.sftd-map-tbl-container-clsd,
.sftd-map-container-clsd {
  margin-top: 2.375rem;
  margin-left: 11.075%;
  padding-top: 1.238rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  background-color: var(--pale-grey);
  min-width: 70.313rem;
  width: 71%;
  height: 4.125rem;
  border-radius: 49px;
  border: solid 1px var(--light-periwinkle);
  margin-bottom: 1rem;
}

.sftd-map-tbl-container {
  padding-top: 0rem;
  min-width: 90rem;
  height: 33.938rem;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  /* background-color: var(--pale-grey); */
  padding-bottom: 5rem;
  /* overflow-y: scroll; */
}

.sftd-mapPg-table-wrapper {
  overflow-y: scroll;
  max-height: 32rem;
  width: 72rem;
  margin-left: 7%;
}

.sftd-mapPg-table-wrapper .sftd-locTable-blinder {
  position: absolute;
  background-color: white;
  width: 70%;
  height: 1.6rem;
}

.sftd-map-tbl-container .selected {
  background-color: white !important;
}

.sftd-map-container .sftd-expand-icon,
.sftd-map-tbl-container .sftd-expand-icon,
.sftd-map-container-clsd .sftd-expand-icon,
.sftd-map-tbl-container-clsd .sftd-expand-icon {
  position: absolute;
  left: 75%;
  width: 1.938rem;
  height: 1.938rem;
  margin-top: 2.438rem;
  margin: 0;
  padding: 0;
  background-size: 1.938rem 1.938rem;
  z-index: 2;
}

.sftd-mapTbl-cont-ttl,
.sftd-MapCont-Title {
  margin: 0rem;
  width: 25.125rem;
  height: 3.125rem;
  font-family: OpenSans;
  font-size: 1.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #292929;
}
.sftd-map-container .sftd-MapCont-Title {
  font-size: 1.75rem;
  margin-left: 13.75rem;
}

.sftd-mapTbl-cont-ttl {
  padding-top: 2.8rem;
  margin-left: 4rem;
  padding-left: 2rem;
  position: sticky;
  top: 0px;
  /* background: var(--pale-grey); */
  width: 100%;
}

.sftd-legend {
  z-index: 2000;
  padding-top: 8px;
  padding-bottom: 8px;
  bottom: 0rem;
  background-color: var(--pale-grey);
  justify-content: center;
  /* min-width: 62.438rem; */
  width: 100rem;
  height: 4.438rem;
  opacity: 0.7;
  box-shadow: 1px 1px 5px 0 var(--light-periwinkle);
  border: 1px solid rgb(173, 169, 169);
}

.leaflet-left .leaflet-control {
  margin-left: 0px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 0px;
}
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  margin-left: 10px;
}

.sftd-modal-slct-txt-unactive {
  color: red;
}
.sftd-modal-slct-txt {
  cursor: pointer;
  text-decoration: underline;
}

.sftd-modal-slct-txt:hover {
  cursor: pointer;
  color: blue;
}

.sftd-key-item1 {
  width: 100%;
  display: grid, inline-block;
  grid-area: item1;
  padding-left: 10px;
  padding-right: 10px;
}
.sftd-key-item2 {
  width: 100%;
  display: grid, inline-block;
  grid-area: item2;
  padding-left: 10px;
  padding-right: 10px;
}
.sftd-key-item3 {
  width: 100%;
  display: grid, inline-block;
  grid-area: item3;
  padding-left: 10px;
  padding-right: 10px;
}
.sftd-key-item4 {
  width: 100%;
  display: grid, inline-block;
  grid-area: item4;
  padding-left: 10px;
  padding-right: 10px;
}

.sftd-key-dot {
  width: 1.188rem;
  height: 1.188rem;
  margin-bottom: 2px;
  margin-top: 0.5rem;
  float: left;
  border-radius: 50%;
  background-color: greenyellow;
  padding: 0px;
}

.sftd-key-text {
  margin-top: 0.5rem;
  margin-left: 2rem;
  padding: 0px;
  width: 10.313rem;
  height: 1.375rem;
  font-family: OpenSans;
  font-size: 0.85rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--smoke);
}
.sftd-parentdiv-note .sftd-key-note {
  margin-top: 0rem;
  margin-left: 5%;
  padding: 0px;
  width: 40.313rem;
  height: 1.375rem;
  font-family: OpenSans;
  font-size: 0.85rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--smoke);
}

.sftd-key-note #underline {
  font-style: italic;
  text-decoration: underline;
}

.sftd-legend .sftd-key-dot.zero {
  background-color: var(--dark-coral);
}

.sftd-legend .sftd-key-dot.one {
  background-color: var(--color-yellow);
}
.sftd-legend .sftd-key-dot.two {
  background-color: var(--pale-teal);
}

.sftd-legend .sftd-key-dot.eleven {
  background-color: var(--color-blue);
}

.sftd-legend .sftd-key-dot.twentytwo {
  background-color: var(--denim);
}
.sftd-legend .sftd-key-dot.buffer {
  background-color: rgb(38, 80, 219);
  width: 20px;
  height: 20px;
}
.sftd-legend .sftd-key-dot.unselected {
  background-color: grey;
}
.sftd-legend .sftd-key-dot.hlfMile {
  background-color: grey;
  width: 20px;
  height: 20px;
}
.sftd-parentdiv-lhs {
  float: left;
  margin-left: 2%;
  width: 9rem;
}

.sftd-legend .sftd-parentdiv {
  float: left;
  margin-left: 10px;
  width: 9.5rem;
}

.sftd-legend .keyNote {
  padding-top: 5px;
  font-size: 9px;
  font-weight: bold;
  color: var(--battleship-grey);
}

.key-Title {
  display: grid;
  grid-area: title;
  font-size: 14px;
  color: var(--battleship-grey);
  margin: 0px;
  justify-content: center;
}

.map-popup-title {
  font-weight: 800;
}
.map-popup-subtitle {
  font-weight: 500;
  text-decoration: underline;
}
.map-popup-bold {
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
}
.map-popup-bold-sub {
  font-weight: 500;
  padding-left: 10px;
}

.sftd-map-container-clsd .sftd-expand-icon,
.sftd-map-tbl-container-clsd .sftd-expand-icon {
  background-image: url('../../images/expand_btn_unselected.png');
}

.sftd-map-container-clsd .sftd-expand-icon:hover,
.sftd-map-tbl-container-clsd .sftd-expand-icon:hover {
  background-image: url('../../images/expand_btn_hover.png');
}
.sftd-map-container-clsd .sftd-expand-icon:active,
.sftd-map-tbl-container-clsd .sftd-expand-icon:active {
  background-image: url('../../images/expand_btn_selected.png');
}

.sftd-map-container .sftd-expand-icon,
.sftd-map-tbl-container .sftd-expand-icon {
  margin-top: 0.5rem;
  background-image: url('../../images/collapse_btn_unselected.png');
}

.sftd-map-tbl-container .sftd-expand-icon {
  margin-top: 3rem;
}

.sftd-map-container .sftd-expand-icon:hover,
.sftd-map-tbl-container .sftd-expand-icon:hover {
  background-image: url('../../images/collapse_btn_hover.png');
}

.sftd-map-container .sftd-expand-icon:active,
.sftd-map-tbl-container .sftd-expand-icon:active {
  background-image: url('../../images/collapse_btn_selected.png');
}

.sftd-map-tbl-container .sftd-map-table-wrapper {
  margin-left: 9%;
  min-width: 62.5rem;
  width: 68%;
}
.sftd-map-tbl-container .sftd-map-table-wrapper .selected,
.sftd-map-tbl-container .sftd-map-table-wrapper .address,
.sftd-map-tbl-container .sftd-map-table-wrapper .selectedCount,
.sftd-map-tbl-container .sftd-map-table-wrapper .count {
  position: sticky;
  top: 0px;
  color: black;
  background-color: white;
  border-bottom: 1px solid black;
  border-collapse: separate;
  border-spacing: 0;
}

/* .sftd-Map-Page .sftd-map-table-wrapper .sftd-table-header .selected {
  top: 6rem;
  left: 1.6rem;
} */

.sftd-map-tbl-container .sftd-map-table-wrapper .sftd-table {
  /* margin-left: 7%; */
  margin-top: 0rem;
  border-collapse: collapse;
  width: 99%;
  height: 100%;
}

.sftd-map-tbl-container .sftd-map-table-wrapper .sftd-table-row {
  height: 2.375rem;
}

.sftd-table-cell-sv,
.sftd-table-cell-tv,
.sftd-table-cell-loc,
.sftd-table-cell-sl {
  border: 1px black solid;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
}
.sftd-table-cell-sv-selected,
.sftd-table-cell-sv {
  width: 8.688rem;
  padding-left: 5px;
}
.sftd-table-cell-tv-selected,
.sftd-table-cell-tv {
  width: 7.688rem;
  padding-left: 5px;
}

.sftd-table-cell-loc-selected,
.sftd-table-cell-loc {
  width: 35.438rem;
  padding-left: 20px;
}

.sftd-table-cell-location-selected,
.sftd-table-cell-location-selected:active {
  background-color: white;
}

.sftd-map-table-wrapper .sftd-table-header .selected {
  border: none;
}
.sftd-table-cell-sv-selected,
.sftd-table-cell-tv-selected,
.sftd-table-cell-loc-selected {
  border: 1px black solid;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
  z-index: 12;
}

.sftd-table-cell-sl,
.sftd-table-cell-sl-selected {
  /* position: sticky; */
  left: 0;
  border-left: 1px solid black;
  z-index: 1;
  /* background-color: white; */
  border-bottom: 1px solid white !important;
  height: 100% !important;
}

.sftd-map-table-wrapper .sftd-table-cell-sl,
.sftd-map-table-wrapper .sftd-table-cell-sl-selected {
  background-color: var(--pale-grey);
  height: 100%;
}

.sftd-map-table-wrapper .sftd-table-cell-sl {
  border-right: 1px #959ba3 solid;
}

.sftd-map-table-wrapper .sftd-table-cell-sl-selected {
  border-right: 1px solid black;
  z-index: 2;
}
/* CSS FOR DASHBOARD */
.swt-main .sftd-table-header .selected {
  z-index: 1;
  height: 1rem;
  width: 32px;
  left: 0rem;
  /* border: 1px solid rgba(136, 133, 133, 0.767); */
  background-color: white;
}

.swt-main .sftd-table-header .selected .swt-sort-arrows {
  margin-right: 0.25rem;
  padding: 0rem;
}

.sftd-vcl-table-wrapper th.btn {
  background-color: white;
  position: sticky;
  right: 0rem;
  border: none;
}

.sftd-vcl-table-wrapper .sftd-table-cell-sl-selected,
.sftd-vcl-table-wrapper .sftd-table-cell-sl {
  position: sticky;
}

.sftd-vcl-table-wrapper th.selected {
  position: sticky;
  min-width: 2.4rem;
  left: 0;
}
.sftd-vcl-btn {
  width: 25px;
  height: 17px;
  /* background-color: rgba(167, 163, 163, 0.637); */
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 7px;
  cursor: pointer;
}

.sftd-vcl-btn {
  background-image: URL('../../images/single_vcl.png');
}
.sftd-vcl-btn:hover {
  background-image: URL('../../images/single_vcl_hover.png');
}
.sftd-vcl-btn:active {
  background-image: URL('../../images/single_vcl_clicked.png');
}

.sftd-vcl-table-wrapper .sftd-map-tbl-container .sftd-table-radio {
  width: 20px;
  height: 1rem;
  margin-left: 0.68rem;
  margin-right: 0.68rem;
}

.sftd-vcl-tble-util-txt {
  color: var(--smoke);
  font-size: 1rem;
  margin: 0px;
  margin-left: 13.5%;
}

.sftd-vcl-tble-util-txt #perc {
  font-size: 1.5rem;
  font-weight: bold;
}

/*  MAP END */

/* VEHICLES PAGE */

.sftd-vcl-toggle {
  margin-left: 49%;
  width: 30rem;
}

.sftd-vcl-table-wrapper {
  width: 68%;
  min-width: 60rem;
  min-height: 70vh;
  overflow: scroll;
  margin-left: 12%;
  margin-bottom: 12.688rem;
  z-index: 15;
}

.sftd-vcl-table-wrapper .sftd-table-blinder-right,
.sftd-vcl-table-wrapper .sftd-table-blinder-left {
  width: 12rem;
  height: 78%;
  background-color: white;
  top: 25rem;
}
.sftd-vcl-table-wrapper .sftd-table-blinder-left {
  position: absolute;
  bottom: 0rem;
  left: 10rem;
}
.sftd-vcl-table-wrapper .sftd-table-blinder-right {
  position: absolute;
  bottom: 0rem;
  right: 9rem;
}

.sftd-table-header .btn .swt-sort-arrows .swt-sort-arrow-both,
.sftd-table-header .btn .swt-sort-arrows .swt-sort-arrow-desc,
.sftd-table-header .btn .swt-sort-arrows .swt-sort-arrow-asc {
  width: 0px;
}

.sftd-vcl-table-wrapper .sftd-table {
  border-collapse: separate;
  border-spacing: 0.01px;
  width: 100%;
  overflow: scroll;
}
.sftd-vcl-table-wrapper .sftd-table td {
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}
.sftd-vcl-table-wrapper .sftd-table td:nth-child(2) {
  border-left: 1px solid black;
}

.sftd-vcl-table-wrapper .sftd-table tr:last-of-type td {
  border-bottom: 1px solid black;
}

.sftd-table-cell-btn,
.sftd-table-cell-btn-selected {
  position: sticky;
  right: 0px;
  border: none;
  width: 4%;
  height: 17px;
  background-color: white;
  border-left: 1px solid black !important;
  border-bottom: 1px solid white !important;
}
.sftd-vcl-table-wrapper .sftd-table-row {
  height: 1.562rem;
}

.sftd-table-cell-sv,
.sftd-table-cell-tv,
.sftd-table-cell-loc,
.sftd-table-cell-sl,
.sftd-table-cell-yr,
.sftd-table-cell-mk,
.sftd-table-cell-ml,
.sftd-table-cell-cl,
.sftd-table-cell-au,
.sftd-table-cell-ms,
.sftd-table-cell-ds,
.sftd-table-cell-ts,
.sftd-table-cell-dc,
.sftd-table-cell-od,
.sftd-table-cell-hs,
.sftd-table-cell-hr,
.sftd-table-cell-ai {
  border: 1px #959ba3 solid;
  border-top: 1px solid black;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
  height: 1.562rem;
  z-index: 8;
  /* z-index: 11; */
}

.sftd-table-cell-sv-selected,
.sftd-table-cell-tv-selected,
.sftd-table-cell-loc-selected,
.sftd-table-cell-sl-selected,
.sftd-table-cell-yr-selected,
.sftd-table-cell-mk-selected,
.sftd-table-cell-ml-selected,
.sftd-table-cell-cl-selected,
.sftd-table-cell-au-selected,
.sftd-table-cell-ms-selected,
.sftd-table-cell-ds-selected,
.sftd-table-cell-ts-selected,
.sftd-table-cell-dc-selected,
.sftd-table-cell-hr-selected,
.sftd-table-cell-hs-selected,
.sftd-table-cell-od-selected,
.sftd-table-cell-ai-selected {
  border: 1px var(--smoke) solid;
  height: 1.562rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--smoke);
}

.sftd-table-cell-sv-selected,
.sftd-table-cell-tv-selected,
.sftd-table-cell-loc-selected {
  font-weight: bold;
}

.sftd-table-row:hover,
.sftd-table-row:hover .sftd-table-cell-ai-selected,
.sftd-table-row:hover .sftd-table-cell-ai {
  background-color: rgb(233, 244, 234);
}

.sftd-table-row:active,
.sftd-table-row:active .sftd-table-cell-ai-selected,
.sftd-table-row:active .sftd-table-cell-ai {
  background-color: var(--pale-teal);
}

.sftd-table th.assetID,
.sftd-table-cell-ai,
.sftd-table-cell-ai-selected,
.sftd-table-row {
  background-color: var(--white);
}

.sftd-table th.assetID,
.sftd-table-cell-ai,
.sftd-table-cell-ai-selected {
  position: sticky;
  left: 2.55rem;
  height: 2.4rem;
  min-width: 12rem;
  z-index: 1;
  padding-left: 0.5rem;
}

.sftd-table-cell-ai-selected {
  font-weight: bold;
}

.sftd-table-cell-ai {
  border-right: 1px solid black;
}

.sftd-table-cell-sl-selected,
.sftd-table-cell-sl {
  border: none;
  border-right: 1px solid white;
  border-bottom: 1px solid white !important;
}

.sftd-vcl-table-wrapper .sftd-table-cell-sl-selected,
.sftd-table-cell-sl {
  background-color: white;
}

.sftd-vcl-table-wrapper .sftd-table {
  overflow: scroll;
  min-width: 70rem;
}

.sftd-table-cell-yr,
.sftd-table-cell-yr-selected {
  min-width: 4.912rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-ms,
.sftd-table-cell-ms-selected {
  min-width: 7.512rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-mk,
.sftd-table-cell-mk-selected {
  min-width: 8rem;
  padding-left: 0.5rem;
}

.sftd-table-cell-ml,
.sftd-table-cell-ml-selected {
  min-width: 12.5rem;
  padding-left: 0.5rem;
}

.sftd-table-cell-cl,
.sftd-table-cell-cl-selected {
  min-width: 5.812rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-au,
.sftd-table-cell-au-selected {
  min-width: 3.2888rem;
  padding-left: 0.5rem;
}

.sftd-table-cell-ds,
.sftd-table-cell-ds-selected {
  min-width: 4.788rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-ts,
.sftd-table-cell-ts-selected {
  min-width: 5.938rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-dc,
.sftd-table-cell-dc-selected {
  min-width: 8.938rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-hr,
.sftd-table-cell-hr-selected {
  min-width: 3.78rem;
  padding-left: 0.5rem;
}

.sftd-table-cell-od,
.sftd-table-cell-od-selected {
  min-width: 9.948rem;
  padding-left: 0.5rem;
}
.sftd-table-cell-hs,
.sftd-table-cell-hs-selected {
  min-width: 18.812rem;
  padding-left: 0.5rem;
}

.sftd-table-radio {
  margin-top: 0.6rem;
  margin-left: 0.175rem;
  width: 1rem;
  height: 1rem;
}
.sftd-details-expanded ~ .sftd-vcl-table-page .pagination {
  bottom: -21rem;
}

.pagination {
  position: absolute;
  /* bottom: -0%; */
  min-width: 60rem;
  margin-top: 5rem;
  margin-bottom: 3%;
  margin-left: 30.5%;
}
.pagination .sftd-prvs-btn {
  margin-right: 20px;
  width: 0.5rem;
  height: 0.813rem;
  border: none;
  background-color: white;
}

.pagination .sftd-next-btn {
  margin-left: 20px;
  width: 0.5rem;
  height: 0.813rem;
  border: none;
  background-color: white;
}

.pagination .sftd-pagination-txt {
  width: 4.375rem;
  height: 1.063rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: var(--light-periwinkle);
}
/* DataSelector */
.sftd-DataSelector {
  padding-top: 1.75rem;
  height: 3.688rem;
  min-width: 90rem;
}
.sftd-DataSelector .sftd-DataSelector-miles-btn,
.sftd-DataSelector .sftd-DataSelector-btn {
  margin-left: 2.25%;
  float: left;
  width: 8.125rem;
  height: 2.25rem;
  border-radius: 7px;
  border: solid 1px var(--light-periwinkle);
  cursor: pointer;
}
.sftd-DataSelector .sftd-DataSelector-miles-btn {
  margin-left: 31%;
}

.sftd-DataSelector .sftd-DataSelector-btn#clicked,
.sftd-DataSelector .sftd-DataSelector-miles-btn#clicked {
  box-shadow: inset 3px 3px 5px 0 var(--light-periwinkle);
  background-color: rgba(128, 128, 128, 0.096);
}
.sftd-DataSelector-ttl {
  margin-top: 0.4rem;
}

/* Duty  Cycle Page */

.sftd-details-expanded ~ .sftd-table-wrapper .sftd-table-legend {
  justify-content: left;
  margin-left: 0rem;
}
.sftd-details-expanded ~ .sftd-table-wrapper .sftd-table-legend p.cell-title {
  margin-left: 13rem;
  left: 0rem;
  float: none;
}
.sftd-details-expanded ~ .sftd-table-wrapper .sftd-table-legend .icon-wrapper {
  position: absolute;
  left: 40rem;
  width: 35rem;
  top: 0.3rem;
}
.sftd-table-wrapper {
  position: relative;
  display: inline;
  min-width: 90rem;
}

.sftd-table-wrapper .sftd-noData-Msg {
  position: relative;
  margin-left: 18%;
}

.sftd-vcl-table-wrapper .sftd-noData-Msg {
  margin-left: 4%;
}

.sftd-dctable-wrapper {
  position: absolute;
  left: 0rem;
  margin-left: 11%;
  margin-right: 13rem;
  min-width: 66.5rem;
  width: 75%;
  max-height: 50rem;
  overflow: scroll;
  border-right: 1px solid var(--smoke);
  margin-bottom: 13.125rem;
  box-sizing: border-box;
  border-bottom: 1px solid black;
}

.sftd-table {
  margin-top: 3rem;
}
.sftd-table-legend {
  height: 4.312rem;
  min-width: 90rem;
}
.sftd-table-legend .cell-text {
  padding-top: 4px;
  float: left;
}
.sftd-table-legend .cell-title {
  float: left;
  width: 26.063rem;
  height: 3.063rem;
  font-family: OpenSans;
  font-size: 2.25rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  color: #292929;
  margin-top: 0.925rem;
  margin-left: 13.688rem;
}

.sftd-dctable-wrapper .sftd-date-activeCell {
  border: 1px solid white;
}

.sftd-outerWrapper {
  margin-top: 1rem;
  position: relative;
}
.sftd-label-blinder-top {
  background-color: white;
  position: absolute;
  top: 0rem;
  left: 11%;
  width: 75%;
  height: 88px;
  z-index: 4;
  /* CHANGE TO 6 */
}

.sftd-dividing-line {
  position: absolute;
  top: 5rem;
  margin-left: 10.8%;
  width: 75%;
  height: 0.01px;
  border-top: 0.02px solid black;
  z-index: 8;
}

.swt-app .swt-main .sftd-dividing-line {
  top: 5.3rem;
}

.sftd-label-blinder-left {
  background-color: white;
  position: absolute;
  top: 0rem;
  left: 11%;
  width: 22.32rem;
  height: 87.5px;
  z-index: 7;
}
.sftd-label-blinder-right {
  background-color: white;
  position: absolute;
  right: 13.95%;
  top: 0rem;
  width: 3px;
  height: 87px;
  z-index: 2;
}
.sftd-dctable-wrapper .sftd-table th:hover,
.sftd-dctable-wrapper .sftd-table tr:hover {
  background-color: none;
}

.sftd-dctable-wrapper .sftd-table {
  display: relative;
  border-spacing: 0.01px;
  empty-cells: show;
  padding-right: 0px;
  width: 70rem;
  table-layout: fixed;
}

.sftd-dctable-wrapper .sftd-table .sftd-inactiveCell,
.sftd-dctable-wrapper .sftd-table .sftd-inactiveCell-unselected,
.sftd-dctable-wrapper .sftd-table .sftd-activeCell,
.sftd-dctable-wrapper .sftd-table .sftd-activeCell-unselected,
.sftd-dctable-wrapper .sftd-table .sftd-utilizationCell,
.sftd-dctable-wrapper .sftd-table .sftd-utilizationCell-unselected,
.sftd-dctable-wrapper .sftd-table .sftd-conflictCell,
.sftd-dctable-wrapper .sftd-table .sftd-conflictCell-unselected {
  border-left: 0.5px solid #b0bbc5;
  border-bottom: 0.25px solid var(--smoke);
}
.sftd-dctable-wrapper .sftd-table .sftd-inactiveCell-unselected {
  background-color: rgba(185, 179, 179, 0.212);
}

.sftd-dctable-wrapper .sftd-table .sftd-deselectedCell {
  border-left: 0.5px solid #b0bbc5;
  border-bottom: 0.25px solid var(--smoke);
  background-color: rgba(134, 131, 131, 0.486);
}
.sftd-dctable-wrapper .sftd-table .sftd-ymm-unselected-cell,
.sftd-dctable-wrapper .sftd-table .sftd-ymm-cell {
  border-bottom: 0.25px solid var(--smoke);
  border-left: 0.25px solid var(--smoke);
}
.sftd-dctable-wrapper .sftd-table .sftd-ymm-unselected-cell#miles,
.sftd-dctable-wrapper .sftd-table .sftd-ymm-unselected-cell#btm-miles,
.sftd-dctable-wrapper .sftd-table .sftd-ymm-cell#miles,
.sftd-dctable-wrapper .sftd-table .sftd-ymm-cell#btm-miles {
  text-align: center;
  border-right: 0.5px solid var(--smoke);
}

.sftd-dctable-wrapper .sftd-table .sftd-ymm-cell#util,
.sftd-dctable-wrapper .sftd-table .sftd-ymm-cell#util {
  text-align: left;
}

.sftd-table-legend .dot {
  float: left;
  margin-top: 1.6rem;
  width: 1.438rem;
  height: 1.375rem;
  border-radius: 50%;
  margin-right: 0.438rem;
  margin-left: 2.438%;
}
.cellIcon-inactive .dot {
  border: 1px solid rgba(180, 178, 178, 0.795);
}

.sftd-conflictCell,
.cellIcon-conflict .dot {
  background-color: var(--pale-orange);
}
.sftd-conflictCell-unselected {
  background-color: rgba(199, 113, 7, 0.658);
}

.sftd-table-legend .cell-text {
  margin-top: 1.562rem;
}

.sftd-table .swt-sort-arrows {
  margin-left: 0.25rem;
}

.sftd-table .swt-sort-arrows .swt-sort-arrow-both,
.sftd-table .swt-sort-arrows .swt-sort-arrow-desc,
.sftd-table .swt-sort-arrows .swt-sort-arrow-asc {
  width: 8px;
}

.sftd-singleVehiclePage .sftd-vcl-value,
.sftd-vcl-utilization-wrpr .sftd-vcl-value,
.sftd-vcl-lst-utilization-wrpr .sftd-vcl-value {
  width: 29.25rem;
  height: 1.3rem;
  font-family: OpenSans;
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--smoke);
  margin: 0rem;
  margin-bottom: 0.1rem;
  margin-left: 1rem;
  padding: 0rem;
}

.sftd-vcl-lst-utilization-wrpr .sftd-vcl-text,
.sftd-vcl-utilization-wrpr .sftd-vcl-text {
  width: 29.25rem;
  height: 1.63rem;
  font-family: OpenSans;
  font-size: 0.775rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
  margin: 0rem;
  margin-left: 1rem;
}

.sftd-singleVehiclePage .sftd-vcl-text,
.sftd-singleVehiclePage .sftd-vcl-lst-utilization-wrpr .sftd-vcl-text {
  width: 29.25rem;
  /* height: 1.63rem; */
  font-family: OpenSans;
  font-size: 0.775rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
  margin: 0rem;
  margin-left: 1rem;
  margin-top: 0.75rem;
}

.sftd-vcl-utilization-wrpr .sftd-utl-line {
  margin-left: 0.594rem;
  width: 88.3%;
  height: 0.01px;
  border: solid 1px var(--light-grey);
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.sftd-vsl-wrapper {
  margin: 1.938rem;
  margin-left: 4%;
  margin-top: 0.1rem;
  height: 73%;
  width: 88%;
}

.sftd-dctable-wrapper .sftd-table .headcol-text {
  width: 8.125rem;
  height: 1.188rem;
  transform: rotate(-35deg);
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #292929;
  padding-bottom: 1.25rem;
  z-index: 4;
}

.stfd-deadCell,
.cellIcon-dead .dot {
  background-color: rgba(74, 80, 131, .8);
}

.sftd-inactiveCell,
.cellIcon-inactive .dot {
  background-color: var(--pale-grey);
}
.sftd-activeCell,
.cellIcon-active .dot {
  background-color: rgba(227, 210, 90, 0.4);
}
.sftd-activeCell-unselected {
  background-color: rgba(153, 144, 72, 0.37);
}
.sftd-utilizationCell,
.cellIcon-utilization .dot {
  background-color: var(--seafoam-blue-51);
}
.sftd-utilizationCell-unselected {
  background-color: rgba(108, 146, 165, 0.459);
}
/*  Set divisions and cell width */
.sftd-table-row-86400 td:nth-child(1n + 5),
.sftd-table-row-86400 th:nth-child(1n + 5) {
  border-left: solid 2px var(--smoke) !important;
}

.sftd-table-row-43200 td:nth-child(2n + 5),
.sftd-table-row-43200 th:nth-child(2n + 5) {
  border-left: solid 2px var(--smoke) !important;
}

.sftd-table-row-21600 td:nth-child(4n + 5),
.sftd-table-row-21600 th:nth-child(4n + 5) {
  border-left: solid 2px var(--smoke) !important;
}

.sftd-table-row-28800 td:nth-child(3n + 5),
.sftd-table-row-28800 th:nth-child(3n + 5) {
  border-left: solid 2px var(--smoke) !important;
}

.sftd-table-row-3600 td:nth-child(24n + 5),
.sftd-table-row-3600 th:nth-child(24n + 5) {
  border-left: solid 2px var(--smoke) !important;
}
.sftd-table-row-43200 td:nth-child(5),
.sftd-table-row-43200 th:nth-child(5),
.sftd-table-row-86400 td:nth-child(5),
.sftd-table-row-86400 th:nth-child(5),
.sftd-table-row-21600 td:nth-child(5),
.sftd-table-row-21600 th:nth-child(5),
.sftd-table-row-28800 td:nth-child(5),
.sftd-table-row-28800 th:nth-child(5),
.sftd-table-row-3600 td:nth-child(5),
.sftd-table-row-3600 th:nth-child(5) {
  border-left: solid 1px white !important;
}
/*  THIS SETS COLUMN WIDTHS FOR ENTIRE TABLE */
.sftd-table-header-86400 .sftd-table-toggle,
.sftd-table-header-43200 .sftd-table-toggle,
.sftd-table-header-21600 .sftd-table-toggle,
.sftd-table-header-28800 .sftd-table-toggle,
.sftd-table-header-3600 .sftd-table-toggle {
  width: 1.312rem !important;
}
.sftd-table-header-86400 .headcol-text#vcl,
.sftd-table-header-43200 .headcol-text#vcl,
.sftd-table-header-21600 .headcol-text#vcl,
.sftd-table-header-28800 .headcol-text#vcl,
.sftd-table-header-3600 .headcol-text#vcl {
  width: 13.812rem !important;
}
.sftd-table-header-86400 .headcol-text#util,
.sftd-table-header-43200 .headcol-text#util,
.sftd-table-header-21600 .headcol-text#util,
.sftd-table-header-28800 .headcol-text#util,
.sftd-table-header-3600 .headcol-text#util {
  width: 2.5rem !important;
}
.sftd-table-header-86400 .headcol-text#miles,
.sftd-table-header-43200 .headcol-text#miles,
.sftd-table-header-21600 .headcol-text#miles,
.sftd-table-header-28800 .headcol-text#miles,
.sftd-table-header-3600 .headcol-text#miles {
  width: 4.062rem !important;
}
.sftd-table-header-86400 .sftd-th-86400 {
  width: 4.45rem !important;
  position: sticky;
  top: 3rem;
  z-index: 6;
}
.sftd-table-header-43200 .sftd-th-43200 {
  width: 4.45rem !important;
  position: sticky;
  top: 3rem;
  z-index: 6;
}
.sftd-table-header-28800 .sftd-th-28800 {
  width: 2.225rem !important;
  position: sticky;
  top: 3rem;
  z-index: 6;
}
.sftd-table-header-21600 .sftd-th-21600 {
  width: 2.225rem !important;
  position: sticky;
  top: 3rem;
  z-index: 6;
}
.sftd-table-header-3600 .sftd-th-3600 {
  width: 0.38rem !important;
  position: sticky;
  top: 3rem;
  z-index: 6;
}

.sftd-deselected-43200 {
  width: 1rem;
}

.sftd-deselected-86400 {
  width: 1rem;
}
.sftd-deselected-21600 {
  width: 0.25rem;
}
.sftd-deselected-28800 {
  width: 0.25rem;
}

.sftd-deselected-3600 {
  width: 0.1rem;
}

/* END OF SETTING COLUMN WIDTHS */
.sftd-deselected-43200,
.sftd-deselected-86400,
.sftd-deselected-21600,
.sftd-deselected-28800,
.sftd-deselected-3600 {
  position: sticky;
  top: 3rem;
  z-index: 6;
  width: 4.45rem;
  transform: rotate(-35deg);
  height: 2.188rem;
  margin-bottom: 1.5rem;
}

.sftd-deselected-21600,
.sftd-deselected-28800 {
  width: 0.75rem;
}

.sftd-deselected-3600 {
  width: 0.1rem;
}

.sftd-deselected {
  position: absolute;
  color: rgba(240, 248, 255, 0);
  font-family: OpenSans;
  font-size: 0.001rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  width: 5rem;
  z-index: 0;
  left: -17px;
  top: 0.7rem;
}

.sftd-dctable-wrapper .sftd-table tr:nth-of-type(1) td {
  border-top: 1px solid black;
}

.sftd-dctable-wrapper .sftd-table tr:last-of-type td {
  border-bottom: 1px solid black;
}
.sftd-noBorder {
  position: sticky;
  left: 0rem;
  border-bottom: 1px solid white !important;
}
.sftd-table-header-3600 .sftd-th-3600,
.sftd-table-header-21600 .sftd-th-21600,
.sftd-table-header-28800 .sftd-th-28800,
.sftd-table-header-43200 .sftd-th-43200,
.sftd-table-header-86400 .sftd-th-86400 {
  text-align: left;
  transform: rotate(-35deg);
  font-family: OpenSans;
  font-size: 0.002px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  padding: 0px;
  margin: 0px;
  color: white;
}
.sftd-table-header-3600 .sftd-th-3600 .sftd-date-container,
.sftd-table-header-21600 .sftd-th-21600 .sftd-date-container,
.sftd-table-header-28800 .sftd-th-28800 .sftd-date-container,
.sftd-table-header-43200 .sftd-th-43200 .sftd-date-container,
.sftd-table-header-86400 .sftd-th-86400 .sftd-date-container {
  position: absolute;
  width: 5rem;
}
.sftd-table-header-86400 .sftd-th-86400 .sftd-date-container {
  left: 0.8rem;
  top: -0.4rem;
}

.sftd-table-header-43200 .sftd-th-43200 .sftd-date-container {
  left: 0.5rem;
  top: 0rem;
}

.sftd-table-header-21600 .sftd-th-21600 .sftd-date-container {
  left: 0.1rem;
  top: 0rem;
}
.sftd-table-header-28800 .sftd-th-28800 .sftd-date-container {
  left: 0.1rem;
  top: 0rem;
}
.sftd-table-header-3600 .sftd-th-3600 .sftd-date-container {
  left: 0.8rem;
  top: 1rem;
}

.sftd-deselected-86400 .sftd-deselected,
.sftd-deselected-43200 .sftd-deselected {
  left: 0rem;
  top: 0rem;
}

.sftd-deselected-21600 .sftd-deselected {
  left: -0.2rem;
  top: 0.8rem;
}

.sftd-deselected-28800 .sftd-deselected {
  left: -0.5rem;
  top: 0.5rem;
}

.sftd-deselected-3600 .sftd-deselected {
  left: -0.5rem;
  top: 0.9rem;
}
.sftd-table-header-86400 .sftd-th-86400 .sftd-date-container,
.sftd-table-header-43200 .sftd-th-43200:nth-child(2n + 1) .sftd-date-container,
.sftd-table-header-21600 .sftd-th-21600:nth-child(4n + 1) .sftd-date-container,
.sftd-table-header-28800 .sftd-th-28800:nth-child(3n + 2) .sftd-date-container,
.sftd-table-header-3600 .sftd-th-3600:nth-child(24n + 1) .sftd-date-container {
  color: #292929;
  font-size: 0.875rem;
}

.sftd-table-header-86400 .sftd-deselected-86400 .sftd-deselected,
.sftd-table-header-43200
  .sftd-deselected-43200:nth-child(2n + 1)
  .sftd-deselected,
.sftd-table-header-21600
  .sftd-deselected-21600:nth-child(4n + 1)
  .sftd-deselected,
.sftd-table-header-28800
  .sftd-deselected-28800:nth-child(3n + 2)
  .sftd-deselected,
.sftd-table-header-3600
  .sftd-deselected-3600:nth-child(24n + 1)
  .sftd-deselected {
  color: grey;
  font-size: 0.875rem;
  z-index: 4;
}

.sftd-table-unselected-toggle,
.sftd-table-toggle,
.sftd-selectCell {
  position: sticky;
  left: 0rem;
  right: 0rem;
  background-color: white;
  border-left: var(--smoke);
  border-top: 1px solid white !important;
  border-bottom: 1px solid white !important;
}

.sftd-table-unselected-toggle .sftd-dv-icon,
.sftd-table-toggle .sftd-dv-icon {
  cursor: pointer;
  background-size: 0.9rem 0.9rem;
  background-repeat: no-repeat;
  width: 0.8rem;
  height: 0.8rem;
  color: white;
}
.sftd-table-toggle .sftd-dv-icon {
  background-image: url('../../images/checkbox_selected_hover.png');
}
.sftd-table-toggle .sftd-dv-icon:hover {
  background-image: url('../../images/checkbox_selected.png');
}
.sftd-table-unselected-toggle .sftd-dv-icon {
  background-image: url('../../images/checkbox_unselected_hover.png');
}

.sftd-table-unselected-toggle .sftd-dv-icon:hover {
  background-image: url('../../images/checkbox_unselected.png');
}

.sftd-ymm-cell#btm-txt,
.sftd-ymm-cell#ymm,
.sftd-ymm-unselected-cell#ymm {
  position: sticky;
  left: 1.35rem;
  padding: 0.2rem;
  right: 0rem;
  background-color: white;
  z-index: 5;
}

.sftd-ymm-cell#btm-utl,
.sftd-ymm-cell#util,
.sftd-ymm-unselected-cell#util {
  position: sticky;
  left: 15.22rem;
  padding: 0.2rem;
  background-color: white;
  z-index: 5;
}

.sftd-dctable-wrapper .sftd-table .headcol-text#util .sftd-label {
  position: absolute;
  width: 3.5rem;
  left: 0.5rem;
  top: 0rem;
  text-decoration: underline;
}

.sftd-dctable-wrapper .sftd-table .headcol-text#miles .sftd-label {
  position: absolute;
  width: 5.5rem;
  left: 1rem;
  top: 0rem;
  text-decoration: underline;
  background-color: white;
}

.sftd-dctable-wrapper .sftd-table .headcol-text#vcl .sftd-label {
  position: absolute;
  width: 4.5rem;
  left: 5.8rem;
  top: 0rem;
  text-decoration: underline;
}

.sftd-ymm-cell#btm-miles,
.sftd-ymm-cell#miles,
.sftd-ymm-unselected-cell#miles {
  position: sticky;
  left: 17.85rem;
  right: 20rem;
  width: 40px;
  background-color: white;
  z-index: 5;
}

.headcol-text#vcl {
  position: sticky;
  left: 1.5rem;
  top: 3rem;
  z-index: 7;
}

.headcol-text#util {
  position: sticky;
  left: 16rem;
  top: 3rem;
  width: 8rem;
  z-index: 7;
}

.headcol-text#miles {
  position: sticky;
  top: 3rem;
  left: 19rem;
  z-index: 7;
}

.sftd-ymm-cell,
.sftd-ymm-unselected-cell {
  position: sticky;
  height: 1.18rem;
}

.headcol-text-gry {
  color: #b0bbc5;
}

.sftd-ymm-unselected-cell,
.sftd-ymm-cell {
  width: 13.313rem !important;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
}
.sftd-ymm-cell {
  color: #292929;
}
.sftd-ymm-unselected-cell {
  color: #b0bbc5;
}

.sftd-cell-assetId {
  width: 13.063rem;
  height: 1.188rem;
  font-family: OpenSans;
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.47px;
  color: #959ba3;
}
.data-select-icon {
  width: 1rem;
  height: 1rem;
}

/* Single Vehicle Page */

.sftd-singleVehiclePage .sftd-vcl-value {
  font-size: 1.5rem;
}

.sftd-singleVcl-backBtn {
  position: absolute;
  left: 13%;
  top: 13.8rem;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  background-size: 2.2rem 2.2rem;
  background-repeat: no-repeat;
  background-image: url('../../images/back-button.png');
  background-position: center;
}

.sftd-singleVcl-backBtn:hover {
  background-image: url('../../images/back-button-hover.png');
}

.sftd-noData-Msg {
  position: absolute;
  width: 55rem;
  font-size: 1.4rem;
  margin-top: 8rem;
  margin-left: 0%;
  border: 1px solid black;
  padding: 2rem;
}

.sftd-map-table-wrapper .sftd-noData-Msg {
  margin-left: 0rem;
}

.react-datepicker__month-container {
  position: relative;
  z-index: 3;
  opacity: 100;
}

.sftd-loading {
  width: 15vw;
  height: 15vh;
  color: 'grey';
  margin-left: 40%;
  margin-top: 5rem;
}

.sftd-table-wrapper .sftd-loading-background {
  position: absolute;
  width: 100rem;
  z-index: 4000;
  color: 'grey';
  margin-top: 13rem;
  height: 87rem;
  background-color: rgb(248, 248, 248);
}

.sftd-table-wrapper .sftd-loading {
  width: 14vw;
  z-index: 4000;
  color: 'grey';
  opacity: 100;
  margin-left: 40rem;
  margin-top: 12rem;
}

@media (max-width: 1616px) {
  .sftd-map-container .sftd-expand-icon,
  .sftd-map-tbl-container .sftd-expand-icon,
  .sftd-map-container-clsd .sftd-expand-icon,
  .sftd-map-tbl-container-clsd .sftd-expand-icon {
    left: 75rem;
    z-index: 200;
  }
  .sftd-yAxis-label {
    left: -8%;
    bottom: 52%;
  }
  .sftd-xAxis-label {
    left: 40%;
    padding-bottom: 2rem;
  }

  .sftd-singleVehiclePage .sftd-xAxis-label {
    left: 46%;
  }

  .navbar_link_home {
    /* position: absolute; */
    margin-left: 7.688%;
  }
  .navbar_link_data {
    /* position: absolute; */
    margin-left: 15.875%;
  }

  .navbar_link_map {
    /* position: absolute; */
    margin-left: 15.062%;
  }

  .navbar_link_vehicle {
    margin-left: 15.875%;
  }
  .pagination {
    position: absolute;
    /* bottom: -0%; */
    min-width: 60rem;
    margin-top: 7rem;
    /* margin-bottom: 3%; */
    margin-left: 26rem;
  }
}

.react-datepicker__navigation {
  z-index: 60 !important;
}

@media (min-width: 1616px) {
  .sftd-location-selector {
    margin-left: 13.688%;
    width: 7.625rem;
    height: 2.25rem;
    cursor: pointer;
  }
}
@media (min-width: 1490px) {
  .sftd-vcl-table-wrapper .sftd-table-blinder-right {
    position: absolute;
    bottom: 0rem;
    right: 10rem;
  }
}
@media (min-width: 1650px) {
  .sftd-vcl-table-wrapper .sftd-table-blinder-right {
    position: absolute;
    bottom: 0rem;
    right: 12rem;
  }
}

/* HOVER */
.sftd-onHoverMessage {
  padding: 8px;
  margin-left: 30px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  box-shadow: 2px 2px 4px var(--ezev-charcoal-grey);
  border-radius: 5px;
  width: 250px;
  height: 50px;
  position: absolute;
  z-index: 200;
  background-color: rgba(141, 138, 138, 0.082);
  font-size: 13px;
}

.sftd-onHoverMessage.timeDelta {
  left: 52rem;
  top: 4.5rem;
}

.sftd-onHoverMessage.pending-time {
  left: 44rem;
  top: 6rem;
  width: 13.3rem;
  height: 1.5rem;
}

.sftd-onHoverMessage.pending-groups {
  left: 58rem;
  top: 6rem;
  width: 14.3rem;
  height: 1.5rem;
}

.sftd-setting-refresh .update {
  position: absolute;
  left: -1.8rem;
  top: 2rem;
  width: 6.3rem;
  height: 0.5rem;
  border: none;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.788);
  font-weight: 400;
  color: var(--pale-teal);
}

.sftd-setting-reset .clear {
  position: absolute;
  left: -1.3rem;
  top: 2rem;
  width: 6.3rem;
  height: 0.5rem;
  border: none;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.568);
  font-weight: 400;
  color: rgb(189, 85, 81);
}

.sftd-duty-cycles-details-wrpr {
  /* position: absolute; */
  padding: 3px;
  display: grid;
  margin-left: 6.5rem;
  width: 74rem;
  grid-template-columns: 25% 25% 25% 25%;
  box-shadow: 0 7px 15px 0 #ecf0f3;
  background-color: rgba(240, 243, 247, 0.877);
}

.sftd-duty-cycles-details-wrpr .sftd-dc-clm1 {
  margin-top: 1rem;
  margin-left: 1.3rem;
}


.sftd-duty-cycles-details-wrpr .sftd-dc-clm2 ,
.sftd-duty-cycles-details-wrpr .sftd-dc-clm3,
.sftd-duty-cycles-details-wrpr .sftd-dc-clm4 {
  margin-top: 1.8rem;
  margin-left: 1.3rem;
}

.sftd-duty-cycles-details-wrpr .sftd-details-title {
  margin: 0rem;
}

.sftd-duty-cycles-details-wrpr .sftd-details-item {
  margin: 0.5rem;
  font-size: 0.9rem;
}

.sftd-duty-cycles-details-wrpr .sftd-details-item .sftd-value-capacity,
.sftd-duty-cycles-details-wrpr .sftd-details-item .sftd-value-active,
.sftd-duty-cycles-details-wrpr .sftd-details-item .sftd-value {
  margin: 0.5rem;
  font-size: .9rem;
}

.sftd-duty-cycles-details-wrpr .sftd-details-item .sftd-value-active {
  color: orange;
}

.sftd-duty-cycles-details-wrpr .sftd-details-item .sftd-value-capacity {
  color: rgb(91, 143, 173);
}

.sftd-singleVcl-details {
  font-size: 0.8rem;
  padding-left: 5px;
  color: black;
}

.sftd-singleVcl-details:hover {
  color: rgb(87, 145, 34);
}
